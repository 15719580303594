import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";

import { IndexPage } from "./pages/index-page";
import { PrivacyPage } from "./pages/PrivacyPage";
import { render } from "react-dom";
import React from "react";
import "./index.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: <IndexPage />,
  },
  {
    path: "/src/:wildcard", // wildcard pattern for /entry_*
    element: <IndexPage />,
  },
  {
    path: "/privacy",
    element: <PrivacyPage/>,
  },
]);

render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
  document.getElementById("root")
);
