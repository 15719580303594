import { Fragment, useEffect, useState } from "react";
import { HeaderAndFooter } from "../components/HeaderAndFooter";
import { getDataFromSheet } from "../api/google-sheets";
import Select from 'react-select'
import { ValidatedGoogleSheetRow } from "../types";
import { generateEmailBody } from "../utils/copy-text";
import { doesCouncilHaveWards, formatWardName, generateCandidatesToEmail, generateGmailAppComposeHref, generateGmailWebComposeHref, generateMailtoHref, getHeap } from "../utils/utils";
import ReactHeap from 'reactjs-heap';
import { ccEmailAddress, version } from "../config";
import "../App.css";
import { useNavigate, useParams } from "react-router-dom";
import { isDesktop, isMobileSafari } from 'react-device-detect';


export function IndexPage() {
  /** This disappears after page load */
  const { wildcard } = useParams<{ wildcard: string }>();
  /** This does not disappear after page load */
  const [entryId, setEntryId] = useState<string | null>(null);

  const navigate = useNavigate();
  useEffect(() => {
    if (wildcard !== undefined && wildcard !== '' && wildcard !== entryId) {
      // Store the wildcard in the state
      setEntryId(wildcard);

      // Redirect to the root path
      navigate("/", { replace: true });
    }
  }, [wildcard, navigate, entryId]);


  useEffect(() => {
    const inDevEnv = process.env.NODE_ENV === 'development';
    console.log('inDevEnv:', inDevEnv);
    ReactHeap.initialize(inDevEnv ? "2929308202" : "286800571");
  }, [])


  const [loadingError, setLoadingError] = useState<boolean | null>(null);
  const [emailList, setEmailList] = useState<ValidatedGoogleSheetRow[] | null>(null);
  const [selectedCouncil, setSelectedCouncil] = useState<string | null>(null);
  const councilWardState = useState<string | null | undefined>(null);
  /**
  * Null if no council yet selected. Undefined if council selected and council has no wards.
  * String when ward selected
 */
  const selectedCouncilWard = councilWardState[0];
  const setSelectedCouncilWard = councilWardState[1];


  const [myName, setMyName] = useState<string | null>(null);
  const heap = getHeap();

  useEffect(() => {
    // @ts-ignore
    if (entryId !== null && heap && heap.loaded) {
      console.log(`Tracking entryId: ${entryId}`);
      heap.track('entry_id', { entryId });
    }
  }, [entryId, heap]);

  useEffect(() => {
    getHeap().track('version', { version });
    async function fetchData() {
      try {
        // Fetch the data from the Google Sheet
        const data = await getDataFromSheet();
        getHeap().track('EmailsReceived', { quantity: data.length });
        setEmailList(data);
      } catch (error) {
        setLoadingError(true);
        console.error(error);
        // @ts-ignore
        getHeap().track('error', { errorAsString: error.toString() });
      }
    }
    fetchData();
  }, []);

  const councilOptions = emailList === null ? null : emailList
    .map(row => row["Council name"])
    // Remove duplicates
    .reduce((acc: string[], councilName: string) => {
      if (!acc.includes(councilName)) {
        acc.push(councilName);
      }
      return acc;
    }, [])

  /** null if data isn't yet available. Empty array if there's no wards in council area. 
   * Otherwise a list of strings
   */
  const wardsInCouncil: null | string[] | string = selectedCouncil === null || emailList === null ? null :
    emailList.filter(row => row["Council name"] === selectedCouncil)
      .map(row => row["Ward name"])
      .filter(wardName => wardName !== "")
      .filter(wardName => wardName !== 'N/A')
      .filter(wardName => wardName !== 'NA')
      .reduce((acc: string[], wardName: string) => {
        if (!acc.includes(wardName)) {
          acc.push(wardName);
        }
        return acc;
      }, [])
  const candidatesToEmail = selectedCouncil !== null && emailList !== null ?
    generateCandidatesToEmail({ selectedCouncil, emailList, selectedCouncilWard }) : null;

  return (
    <HeaderAndFooter>
      <p>NSW local government elections are on
        the <a
          target="_blank" rel="noopener noreferrer"
          href="https://elections.nsw.gov.au/elections/local-government-elections/2024-nsw-local-government-elections">14th of
          September</a>, so now is the time to ask your local councillors to commit to <a
            target="_blank" rel="noopener noreferrer"
            href="https://www.betterstreets.org.au/2024-nsw-local-candidate-pledge">30km/h
          local streets, safe cycling infrastructure and supporting local businesses</a>.</p>

      <p>This form will help you write an email to your local councillors (in your ward).</p>

      <p>After typing your name it takes 3 clicks!</p>

      <h2>1. Enter your name (not email)</h2>
      <input
        autoComplete="name"
        data-testid="name-input"
        placeholder="Your name"
        type="text" onChange={(event) => setMyName(event.target.value)} />

      {loadingError === true ?
        <p>There was an error loading the data.
          Please reload the page, and if it fails again contact <a href="mailto:jake@jakecoppinger.com">jake@jakecoppinger.com</a>. Thanks!</p> :
        null
      }

      {councilOptions === null || emailList === null ?
        (loadingError === null ? <p>Loading councils...</p> : null) :
        <Fragment>
          <h2>2. Select your council</h2>
          <Select
            id="council-select"
            isDisabled={myName === null}
            options={councilOptions.map(councilName => ({ value: councilName, label: councilName }))}
            // Console.log the selected result
            onChange={(selectedOption) => {
              const value = selectedOption?.value;
              if (value === undefined) {
                return;
              }
              setSelectedCouncil(value);

              if (doesCouncilHaveWards(emailList.filter(row => row["Council name"] === value)) === false) {
                console.log(`setting council ward to undefined because ${value} has no wards`);
                setSelectedCouncilWard(undefined);
              }

              getHeap().track('council_selected', { council: value });
              // Note: Name is not logged
              getHeap().addUserProperties({ council: value });
            }}
          />
        </Fragment>
      }

      {selectedCouncil === null || wardsInCouncil === null || wardsInCouncil.length === 0 ?
        null :
        <Fragment>
          <h2>3. Select council ward</h2>
          <Select
            id="ward-select"
            options={wardsInCouncil.map(wardName => ({ value: wardName, label: wardName }))}
            // Console.log the selected result
            onChange={(selectedOption) => {
              const value = selectedOption?.value;
              if (value === undefined) {
                return;
              }
              setSelectedCouncilWard(value);
              getHeap().track('ward_selected', { ward: value });
              getHeap().addUserProperties({ ward: value });
            }}
          />
        </Fragment>
      }
      <RenderCandidatesListAndLinks candidatesToEmail={candidatesToEmail} myName={myName}
        selectedCouncil={selectedCouncil} selectedCouncilWard={selectedCouncilWard} />
    </HeaderAndFooter >
  );
};
function RenderCandidatesListAndLinks({
  candidatesToEmail, myName, selectedCouncil, selectedCouncilWard }:
  {
    candidatesToEmail: ValidatedGoogleSheetRow[] | null, myName: string | null, selectedCouncil: string | null,

    /**
    * Null if no council yet selected. Undefined if council selected and council has no wards.
    * String when ward selected
   */
    selectedCouncilWard: string | null | undefined
  }) {
  const debug = false;

  if (selectedCouncil === null) {
    return debug ? <p>Selected council is null</p> : null;
  }
  if (myName === null) {
    return debug ? <p>My name is null</p> : null;
  }
  if (candidatesToEmail === null) {
    return debug ? <p>Candidates to email is null</p> : null;
  }
  if (selectedCouncilWard === null) {
    return debug ? <p>Selected council ward is null (no council selected, and council definitely has wards)</p> : null;
  }

  return <Fragment>
    <FoundCandidatesList candidatesToEmail={candidatesToEmail} />
    <RenderMailtoAndLink candidatesToEmail={candidatesToEmail.map(row => row["Email"])}
      myName={myName} councilName={selectedCouncil} wardName={selectedCouncilWard} />
  </Fragment>

}

function FoundCandidatesList({ candidatesToEmail }: { candidatesToEmail: ValidatedGoogleSheetRow[] }) {
  const councilHasWards = doesCouncilHaveWards(candidatesToEmail);
  return <Fragment>
    <h3>Found candidates to email</h3>
    <ul>
      {candidatesToEmail.map(row =>
        <li key={row["Email"]}>
          <span>{row["Councillor Name"]}</span>
          <span className={
            PartyAffiliationToClassname(
              row["Party Affiliation"])}>
            {row["Party Affiliation"] ? ` (${row["Party Affiliation"]})` : ''}</span>
          {councilHasWards && row["Ward name"] ? <span> / {formatWardName(row["Ward name"])}</span> : null}
        </li>
      )}
    </ul>

    {/* <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Party Affiliation</th>
        </tr>
      </thead>
      <tbody>
        {candidatesToEmail.map(row =>
          <tr key={row["Email"]}>
            <td>{row["Councillor Name"]}</td>
            <td>{row["Email"]}</td>
            <td className={PartyAffiliationToClassname(row["Party Affiliation"])}>{row["Party Affiliation"] ? row["Party Affiliation"] : '?'}</td>
          </tr>
        )}
      </tbody>
    </table> */}
    <p><i>Please <a data-testid="incorrect-data-mailto-contact" href="mailto:jake@jakecoppinger.com">contact</a> if you find incorrect data.
      This data is not guaranteed to be accurate.</i></p>
  </Fragment>
}

function PartyAffiliationToClassname(partyAffiliation: 'Independent' | 'Greens' | 'Labor' |
  'Liberal' | undefined):
  'party-independent' | 'party-greens' | 'party-labor' | 'party-liberal' | undefined {
  if (partyAffiliation === 'Independent') {
    return 'party-independent';
  }
  if (partyAffiliation === 'Greens') {
    return 'party-greens';
  }
  if (partyAffiliation === 'Labor') {
    return 'party-labor';
  }
  if (partyAffiliation === 'Liberal') {
    return 'party-liberal';
  }
  return undefined;
}

function RenderMailtoAndLink({ candidatesToEmail, myName, councilName, wardName }:
  { candidatesToEmail: string[], myName: string, councilName: string, wardName?: string | null }) {
  const body = generateEmailBody({
    councilName,
    myName,
    wardName: wardName !== null ? wardName : undefined
  });
  const emailSubject = "Request for better, safer streets"

  return <Fragment>
    <h2>Send your email!</h2>
    <h2>
      <a
        target="_blank" rel="noopener noreferrer"
        data-testid="mailto-link"
        // Note: Href isn't captured by heap analytics as it contains name
        data-heap-redact-attributes='href'
        href={generateMailtoHref({
          toEmails: candidatesToEmail,
          ccEmails: [ccEmailAddress],
          subject: emailSubject,
          body
        })}
      >
        Open pre-filled draft in your default email app (1-click)
      </a>
    </h2>

    {isDesktop ?
      <Fragment>
        <p><i>or</i></p>
        <h2>
          <a
            target="_blank" rel="noopener noreferrer"
            data-testid="gmail-compose-link"
            // Note: Href isn't captured by heap analytics as it contains name
            data-heap-redact-attributes='href'
            href={generateGmailWebComposeHref({
              toEmails: candidatesToEmail,
              ccEmails: [ccEmailAddress],
              subject: emailSubject,
              body
            })}
          >
            Open pre-filled draft on Gmail website (1-click) (mail.google.com)
          </a>
        </h2>

      </Fragment>
      : null}

    {isMobileSafari ?
      <Fragment>
        <p><i>or</i></p>
        <h2>
          <a
            target="_blank" rel="noopener noreferrer"
            data-testid="gmail-compose-link"
            // Note: Href isn't captured by heap analytics as it contains name
            data-heap-redact-attributes='href'
            href={generateGmailAppComposeHref({
              toEmails: candidatesToEmail,
              ccEmails: [ccEmailAddress],
              subject: emailSubject,
              body
            })}
          >
            Open pre-filled draft in Gmail app (1-click)
          </a>
        </h2>
      </Fragment>
      : null}

    <p>If the above {isMobileSafari || isDesktop ? `links don't open the right app` : `link opens the wrong app`}, copy these fields into your email app:</p>

    <h3>Suggested email title</h3>
    <CopyToClipboardButton clipboardContent={emailSubject} testId="copy-email-subject-button" />
    <p>{emailSubject}</p>

    <h3>Suggested email recipient list</h3>
    <CopyToClipboardButton clipboardContent={candidatesToEmail.join(", ")} testId="copy-email-recipients-button" />
    <p>{candidatesToEmail.join(", ")}</p>

    <h3>Suggested cc email list</h3>
    <CopyToClipboardButton clipboardContent={ccEmailAddress} testId="copy-cc-address-button" />
    <p>{ccEmailAddress}</p>

    <h3>Suggested email body text</h3>
    <CopyToClipboardButton clipboardContent={body} testId="copy-email-body-button" />
    <p style={{ whiteSpace: 'pre-line' }}>{body}</p>
  </Fragment>
}

function CopyToClipboardButton({ clipboardContent, testId }: { clipboardContent: string, testId: string }) {
  return <button
    data-testid={testId}
    onClick={() => navigator.clipboard.writeText(clipboardContent)}>
    Copy to clipboard</button>
};