import { GoogleSheetRow } from "../types";
export const cachedSheetsResponse: GoogleSheetRow[] = [
    {
        "Council name": " Council of the City of Sydney",
        "Ward name": "",
        "Councillor Name": "Councillor Clover Moore AO - Lord Mayor",
        "New or existing counciller": "",
        "Email": "cmoore@cityofsydney.nsw.gov.au",
        "Party Affiliation": "Independent",
        "Other notes:": "- Locked anomyous edits to comment only to prevent bugs breaking the dropdown similar to https://betterstreets.slack.com/archives/C07GKKDSGAY/p1723505417197529?thread_ts=1723494868.454259&cid=C07GKKDSGAY"
    },
    {
        "Council name": " Council of the City of Sydney",
        "Ward name": "",
        "Councillor Name": "Councillor Robert Kok (Deputy Chair) - Deputy Lord Mayor",
        "New or existing counciller": "",
        "Email": "rkok@cityofsydney.nsw.gov.au",
        "Party Affiliation": "Independent",
        "Other notes:": "- Please comment requests  / suggestions for any extra contacts / decision makers"
    },
    {
        "Council name": " Council of the City of Sydney",
        "Ward name": "",
        "Councillor Name": "Councillor HY William Chan",
        "New or existing counciller": "",
        "Email": "WChan@cityofsydney.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": " Council of the City of Sydney",
        "Ward name": "",
        "Councillor Name": "Councillor (Waskam) Emelda Davis",
        "New or existing counciller": "",
        "Email": "EDavis@cityofsydney.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": " Council of the City of Sydney",
        "Ward name": "",
        "Councillor Name": "Councillor Sylvie Ellsmore",
        "New or existing counciller": "",
        "Email": "SEllsmore@cityofsydney.nsw.gov.au",
        "Party Affiliation": "Greens"
    },
    {
        "Council name": " Council of the City of Sydney",
        "Ward name": "",
        "Councillor Name": "Councillor Lyndon Gannon",
        "New or existing counciller": "",
        "Email": "LGannon@cityofsydney.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": " Council of the City of Sydney",
        "Ward name": "",
        "Councillor Name": "Councillor Shauna Jarrett",
        "New or existing counciller": "",
        "Email": "SMJarrett@cityofsydney.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": " Council of the City of Sydney",
        "Ward name": "",
        "Councillor Name": "Councillor Linda Scott",
        "New or existing counciller": "",
        "Email": "LScott@cityofsydney.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": " Council of the City of Sydney",
        "Ward name": "",
        "Councillor Name": "Councillor Yvonne Weldon AM",
        "New or existing counciller": "",
        "Email": "YWeldon@cityofsydney.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": " Council of the City of Sydney",
        "Ward name": "",
        "Councillor Name": "Councillor Adam Worling",
        "New or existing counciller": "",
        "Email": "AWorling@cityofsydney.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Waverley Council",
        "Ward name": "",
        "Councillor Name": "Councillor Paula Masselos - Lord Mayor",
        "New or existing counciller": "",
        "Email": "paula.masselos@waverley.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Waverley Council",
        "Ward name": "",
        "Councillor Name": "Councillor Ludovico Fabiano - Deputy Lord Mayor",
        "New or existing counciller": "",
        "Email": "Ludovico.fabiano@waverley.nsw.gov.au",
        "Party Affiliation": "Greens"
    },
    {
        "Council name": "Waverley Council",
        "Ward name": "Bondi Ward - Bondi Beach (most), North Bondi (east), Bondi (east)",
        "Councillor Name": "Councillor Dominic Wy Kanak ",
        "New or existing counciller": "",
        "Email": "Dominic.Kanak@waverley.nsw.gov.au",
        "Party Affiliation": "Greens"
    },
    {
        "Council name": "Waverley Council",
        "Ward name": "Bondi Ward - Bondi Beach (most), North Bondi (east), Bondi (east)",
        "Councillor Name": "Councillor Leon Goltsman",
        "New or existing counciller": "",
        "Email": "leon.goltsman@waverley.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Waverley Council",
        "Ward name": "Bondi Ward - Bondi Beach (most), North Bondi (east), Bondi (east)",
        "Councillor Name": "Councillor Michelle Gray",
        "New or existing counciller": "",
        "Email": "michelle.gray@waverley.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Waverley Council",
        "Ward name": "Hunter Ward - Dover Heights, North Bondi (west), Rose Bay (part) and Vaucluse (part)",
        "Councillor Name": "Councillor Sally Betts",
        "New or existing counciller": "",
        "Email": "Sally.Betts@waverley.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Waverley Council",
        "Ward name": "Hunter Ward - Dover Heights, North Bondi (west), Rose Bay (part) and Vaucluse (part)",
        "Councillor Name": "Councillor Steven Lewis",
        "New or existing counciller": "",
        "Email": "Steven.Lewis@waverley.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Waverley Council",
        "Ward name": "Hunter Ward - Dover Heights, North Bondi (west), Rose Bay (part) and Vaucluse (part)",
        "Councillor Name": "Councillor Will Nemesh",
        "New or existing counciller": "",
        "Email": "Will.Nemesh@waverley.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Waverley Council",
        "Ward name": "Lawson Ward - Queens Park, Bondi Junction (most), Bronte (south), and Waverley (south and west)",
        "Councillor Name": "Councillor Angela Burrill",
        "New or existing counciller": "",
        "Email": "Angela.Burrill@waverley.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Waverley Council",
        "Ward name": "Lawson Ward - Queens Park, Bondi Junction (most), Bronte (south), and Waverley (south and west)",
        "Councillor Name": "Councillor Elaine Keenan",
        "New or existing counciller": "",
        "Email": "Elaine.Keenan@waverley.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Waverley Council",
        "Ward name": "Waverley Ward - Tamarama, Bondi (most), Bronte (north), Waverley (north-east), Bondi Junction (east), Bondi Beach (part)",
        "Councillor Name": "Councillor Tim Murray",
        "New or existing counciller": "",
        "Email": "tim.murray@waverley.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Waverley Council",
        "Ward name": "Waverley Ward - Tamarama, Bondi (most), Bronte (north), Waverley (north-east), Bondi Junction (east), Bondi Beach (part)",
        "Councillor Name": "Councillor Tony Kay",
        "New or existing counciller": "",
        "Email": "tony.kay@waverley.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Woollahra Municipal Council",
        "Ward name": "",
        "Councillor Name": "Councillor Richard Shields - Lord Mayor",
        "New or existing counciller": "",
        "Email": "richard.shields@woollahra.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Woollahra Municipal Council",
        "Ward name": "",
        "Councillor Name": "Councillor Sarah Swan - Deputy Lord Mayor",
        "New or existing counciller": "",
        "Email": "sarah.swan@woollahra.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Woollahra Municipal Council",
        "Ward name": "Bellevue Hill Ward - Bellevue Hill - Approx Boundary - Bellevue Road, Victoria Road, O'Sullivan Road",
        "Councillor Name": "Councillor Sean Carmichael",
        "New or existing counciller": "",
        "Email": "sean.carmichael@woollahra.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Woollahra Municipal Council",
        "Ward name": "Bellevue Hill Ward - Bellevue Hill - Approx Boundary - Bellevue Road, Victoria Road, O'Sullivan Road",
        "Councillor Name": "Councillor Lucinda Regan",
        "New or existing counciller": "",
        "Email": "lucinda.regan@woollahra.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Woollahra Municipal Council",
        "Ward name": "Bellevue Hill Ward - Bellevue Hill - Approx Boundary - Bellevue Road, Victoria Road, O'Sullivan Road",
        "Councillor Name": "Councillor Isabelle Shapiro",
        "New or existing counciller": "",
        "Email": "isabelle.shapiro@woollahra.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Woollahra Municipal Council",
        "Ward name": "Bellevue Hill Ward - Bellevue Hill - Approx Boundary - Bellevue Road, Victoria Road, O'Sullivan Road",
        "Councillor Name": "Councillor Luise Elsing",
        "New or existing counciller": "",
        "Email": "luise.elsing@woollahra.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Woollahra Municipal Council",
        "Ward name": "Cooper Ward - Bellevue Hill - Approx Boundary - Ocean Street, Jersey Road",
        "Councillor Name": "Councillor Nicola Grieve",
        "New or existing counciller": "",
        "Email": "nicola.grieve@woollahra.nsw.gov.au",
        "Party Affiliation": "Greens"
    },
    {
        "Council name": "Woollahra Municipal Council",
        "Ward name": "Double Bay Ward - Double Bay, Rushcutters Bay, Darling Point, Point Piper",
        "Councillor Name": "Councillor Mark Silcocks",
        "New or existing counciller": "",
        "Email": "mark.silcocks@woollahra.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Woollahra Municipal Council",
        "Ward name": "Double Bay Ward - Double Bay, Rushcutters Bay, Darling Point, Point Piper",
        "Councillor Name": "Councillor Toni Zeltzer",
        "New or existing counciller": "",
        "Email": "toni.zeltzer@woollahra.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Woollahra Municipal Council",
        "Ward name": "Paddington Ward - Paddington - Approx Boundary - Oxford Street, Barcom Avenue, Approx Boundary Street, Neild Avenue, Jersey Road",
        "Councillor Name": "Councillor Peter Cavanagh",
        "New or existing counciller": "",
        "Email": "peter.cavanagh@woollahra.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Woollahra Municipal Council",
        "Ward name": "Paddington Ward - Paddington - Approx Boundary - Oxford Street, Barcom Avenue, Approx Boundary Street, Neild Avenue, Jersey Road",
        "Councillor Name": "Councillor Harriet Price",
        "New or existing counciller": "",
        "Email": "harriet.price@woollahra.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Woollahra Municipal Council",
        "Ward name": "Paddington Ward - Paddington - Approx Boundary - Oxford Street, Barcom Avenue, Approx Boundary Street, Neild Avenue, Jersey Road",
        "Councillor Name": "Councillor Matthew Robertson",
        "New or existing counciller": "",
        "Email": "matthew.robertson@woollahra.nsw.gov.au",
        "Party Affiliation": "Greens"
    },
    {
        "Council name": "Woollahra Municipal Council",
        "Ward name": "Vaucluse Ward - Vaucluse Point, Watsons Bay, Rose Bay, Vaucluse - Approx Approx Boundary - Newcastle Street, Old South Head Road",
        "Councillor Name": "Councillor Mary-Lou Jarvis",
        "New or existing counciller": "",
        "Email": "mary-lou.jarvis@woollahra.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Woollahra Municipal Council",
        "Ward name": "Vaucluse Ward - Vaucluse Point, Watsons Bay, Rose Bay, Vaucluse - Approx Approx Boundary - Newcastle Street, Old South Head Road",
        "Councillor Name": "Councillor Merrill Witt",
        "New or existing counciller": "",
        "Email": "merrill.witt@woollahra.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Woollahra Municipal Council",
        "Ward name": "Vaucluse Ward - Vaucluse Point, Watsons Bay, Rose Bay, Vaucluse - Approx Approx Boundary - Newcastle Street, Old South Head Road",
        "Councillor Name": "Councillor Susan Wynne",
        "New or existing counciller": "",
        "Email": "susan.wynne@woollahra.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Inner West Council",
        "Ward name": "",
        "Councillor Name": "Councillor Darcy Byrne - Lord Mayor",
        "New or existing counciller": "",
        "Email": "Darcy.Byrne@innerwest.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Inner West Council",
        "Ward name": "",
        "Councillor Name": "Councillor Chloe Smith - Deputy Lord Mayor",
        "New or existing counciller": "",
        "Email": "Chloe.Smith@innerwest.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Inner West Council",
        "Ward name": "Baludarri Ward - Balmain",
        "Councillor Name": "Councillor Kobi Shetty",
        "New or existing counciller": "",
        "Email": "Kobi.Shetty@innerwest.nsw.gov.au",
        "Party Affiliation": "Greens"
    },
    {
        "Council name": "Inner West Council",
        "Ward name": "Baludarri Ward - Balmain",
        "Councillor Name": "Councillor John Stamolis",
        "New or existing counciller": "",
        "Email": "John.Stamolis@innerwest.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Inner West Council",
        "Ward name": "Damun Ward - Stanmore",
        "Councillor Name": "Councillor Liz Atkins",
        "New or existing counciller": "",
        "Email": "Liz.Atkins@innerwest.nsw.gov.au",
        "Party Affiliation": "Greens"
    },
    {
        "Council name": "Inner West Council",
        "Ward name": "Damun Ward - Stanmore",
        "Councillor Name": "Councillor Pauline Lockie",
        "New or existing counciller": "",
        "Email": "Pauline.Lockie@innerwest.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Inner West Council",
        "Ward name": "Djarrawunang Ward - Ashfield",
        "Councillor Name": "Councillor Jessica D'Arienzo",
        "New or existing counciller": "",
        "Email": "Jessica.DArienzo@innerwest.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Inner West Council",
        "Ward name": "Djarrawunang Ward - Ashfield",
        "Councillor Name": "Councillor Mark Drury",
        "New or existing counciller": "",
        "Email": "Mark.Drury@innerwest.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Inner West Council",
        "Ward name": "Djarrawunang Ward - Ashfield",
        "Councillor Name": "Councillor Dylan Griffiths",
        "New or existing counciller": "",
        "Email": "Dylan.Griffiths@innerwest.nsw.gov.au",
        "Party Affiliation": "Greens"
    },
    {
        "Council name": "Inner West Council",
        "Ward name": "Gulgadya Ward - Leichhardt",
        "Councillor Name": "Councillor Marghanita Da Cruz",
        "New or existing counciller": "",
        "Email": "Marghanita.Da.Cruz@innerwest.nsw.gov.au",
        "Party Affiliation": "Greens"
    },
    {
        "Council name": "Inner West Council",
        "Ward name": "Gulgadya Ward - Leichhardt",
        "Councillor Name": "Councillor Philippa Scott",
        "New or existing counciller": "",
        "Email": "Philippa.Scott@innerwest.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Inner West Council",
        "Ward name": "Gulgadya Ward - Leichhardt",
        "Councillor Name": "Councillor Tim Stephens",
        "New or existing counciller": "",
        "Email": "Tim.Stephens@innerwest.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Inner West Council",
        "Ward name": "Midjuburi Ward - Marrickville",
        "Councillor Name": "Councillor Mat Howard",
        "New or existing counciller": "",
        "Email": "Mat.Howard@innerwest.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Inner West Council",
        "Ward name": "Midjuburi Ward - Marrickville",
        "Councillor Name": "Councillor Justine Langford",
        "New or existing counciller": "",
        "Email": "Justine.Langford@innerwest.nsw.gov.au",
        "Party Affiliation": "Greens"
    },
    {
        "Council name": "Inner West Council",
        "Ward name": "Midjuburi Ward - Marrickville",
        "Councillor Name": "Councillor Zoi Tsardoulias",
        "New or existing counciller": "",
        "Email": "Zoi.Tsardoulias@innerwest.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "North Sydney Council",
        "Ward name": "",
        "Councillor Name": "Councillor Zoë Baker - Lord Mayor",
        "New or existing counciller": "",
        "Email": "mayor@northsydney.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "North Sydney Council",
        "Ward name": "",
        "Councillor Name": "Councillor Godfrey Santer - Deputy Lord Mayor",
        "New or existing counciller": "",
        "Email": "Councillor Godfrey.Santer@northsydney.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "North Sydney Council",
        "Ward name": "St Leonards Ward - Cammeray, Crows Nest, Cremorne (part), Wollstonecraft",
        "Councillor Name": "Councillor William Bourke",
        "New or existing counciller": "",
        "Email": "Councillor William.Bourke@northsydney.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "North Sydney Council",
        "Ward name": "St Leonards Ward - Cammeray, Crows Nest, Cremorne (part), Wollstonecraft",
        "Councillor Name": "Councillor John Lepouris",
        "New or existing counciller": "",
        "Email": "Councillor john.lepouris@northsydney.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "North Sydney Council",
        "Ward name": "St Leonards Ward - Cammeray, Crows Nest, Cremorne (part), Wollstonecraft",
        "Councillor Name": "Councillor James Spenceley",
        "New or existing counciller": "",
        "Email": "Councillor James.Spenceley@northsydney.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "North Sydney Council",
        "Ward name": "Cammeraygal Ward - Cremorne Point, Cremorne (part), Neutral Bay, North Sydney, McMahons Point, Kirribilli, Kurraba Point",
        "Councillor Name": "Councillor MaryAnn Beregi",
        "New or existing counciller": "",
        "Email": "Councillor maryann.beregi@northsydney.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "North Sydney Council",
        "Ward name": "Cammeraygal Ward - Cremorne Point, Cremorne (part), Neutral Bay, North Sydney, McMahons Point, Kirribilli, Kurraba Point",
        "Councillor Name": "Councillor Jilly Gibson",
        "New or existing counciller": "",
        "Email": "Councillor Jilly.Gibson@northsydney.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "North Sydney Council",
        "Ward name": "Cammeraygal Ward - Cremorne Point, Cremorne (part), Neutral Bay, North Sydney, McMahons Point, Kirribilli, Kurraba Point",
        "Councillor Name": "Councillor Georgia Lamb",
        "New or existing counciller": "",
        "Email": "Councillor Georgia.Lamb@northsydney.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "North Sydney Council",
        "Ward name": "Cammeraygal Ward - Cremorne Point, Cremorne (part), Neutral Bay, North Sydney, McMahons Point, Kirribilli, Kurraba Point",
        "Councillor Name": "Councillor Ian Mutton",
        "New or existing counciller": "",
        "Email": "Councillor ian.mutton@northsydney.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "North Sydney Council",
        "Ward name": "Cammeraygal Ward - Cremorne Point, Cremorne (part), Neutral Bay, North Sydney, McMahons Point, Kirribilli, Kurraba Point",
        "Councillor Name": "Councillor Shannon Welch",
        "New or existing counciller": "",
        "Email": "Councillor Shannon.Welch@northsydney.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Bayside Council",
        "Ward name": "",
        "Councillor Name": "Councillor Bill Saravinovski - Lord Mayor",
        "New or existing counciller": "",
        "Email": "Bill.Saravinovski@bayside.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Bayside Council",
        "Ward name": "",
        "Councillor Name": "Councillor Joe Awada - Deputy Lord Mayor",
        "New or existing counciller": "",
        "Email": "Joe.Awada@bayside.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Bayside Council",
        "Ward name": "Ward 1 - Botany, Eastlakes, Pagewood, Daceyville, Eastgardens, Hillsdale and Banksmeadow",
        "Councillor Name": "Councillor Christina Curry ",
        "New or existing counciller": "",
        "Email": "mayor@bayside.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Bayside Council",
        "Ward name": "Ward 1 - Botany, Eastlakes, Pagewood, Daceyville, Eastgardens, Hillsdale and Banksmeadow",
        "Councillor Name": "Councillor Scott Morrissey ",
        "New or existing counciller": "",
        "Email": "scott.morrissey@bayside.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Bayside Council",
        "Ward name": "Ward 1 - Botany, Eastlakes, Pagewood, Daceyville, Eastgardens, Hillsdale and Banksmeadow",
        "Councillor Name": "Councillor Jennifer Muscat",
        "New or existing counciller": "",
        "Email": "jennifer.muscat@bayside.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Bayside Council",
        "Ward name": "Ward 2 - Arncliffe, Turrella, Wolli Creek, Mascot and Rosebery",
        "Councillor Name": "Councillor Ann Fardell",
        "New or existing counciller": "",
        "Email": "ann.fardell@bayside.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Bayside Council",
        "Ward name": "Ward 2 - Arncliffe, Turrella, Wolli Creek, Mascot and Rosebery",
        "Councillor Name": "Councillor Jo Jansyn",
        "New or existing counciller": "",
        "Email": "Jo.Jansyn@bayside.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Bayside Council",
        "Ward name": "Ward 2 - Arncliffe, Turrella, Wolli Creek, Mascot and Rosebery",
        "Councillor Name": "Councillor Michael Nagi",
        "New or existing counciller": "",
        "Email": "Michael.Nagi@bayside.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Bayside Council",
        "Ward name": "Ward 3 - Bardwell Park, Bardwell Valley, Banksia and Rockdale",
        "Councillor Name": "Councillor Andrew Tsounis",
        "New or existing counciller": "",
        "Email": "Andrew.tsounis@bayside.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Bayside Council",
        "Ward name": "Ward 3 - Bardwell Park, Bardwell Valley, Banksia and Rockdale",
        "Councillor Name": "Councillor Greta Werner",
        "New or existing counciller": "",
        "Email": "Greta.Werner@bayside.nsw.gov.au",
        "Party Affiliation": "Greens"
    },
    {
        "Council name": "Bayside Council",
        "Ward name": "Ward 4 - Bexley, Bexley North, Kogarah, Carlton and Kingsgrove",
        "Councillor Name": "Councillor Liz Barlow",
        "New or existing counciller": "",
        "Email": "Liz.Barlow@bayside.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Bayside Council",
        "Ward name": "Ward 4 - Bexley, Bexley North, Kogarah, Carlton and Kingsgrove",
        "Councillor Name": "Councillor Mark Hanna",
        "New or existing counciller": "",
        "Email": "Mark.Hanna@bayside.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Bayside Council",
        "Ward name": "Ward 5 - Kyeemagh, Brighton Le Sands, Monterey, Ramsgate Beach, Ramsgate, Dolls Point, Sans Souci and Sandringham",
        "Councillor Name": "Councillor Heidi Lee Douglas",
        "New or existing counciller": "",
        "Email": "HeidiLee.Douglas@bayside.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Bayside Council",
        "Ward name": "Ward 5 - Kyeemagh, Brighton Le Sands, Monterey, Ramsgate Beach, Ramsgate, Dolls Point, Sans Souci and Sandringham",
        "Councillor Name": "Councillor Ed McDougall",
        "New or existing counciller": "",
        "Email": "ed.mcdougall@bayside.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Bayside Council",
        "Ward name": "Ward 5 - Kyeemagh, Brighton Le Sands, Monterey, Ramsgate Beach, Ramsgate, Dolls Point, Sans Souci and Sandringham",
        "Councillor Name": "Councillor Paul Sedrak",
        "New or existing counciller": "",
        "Email": "Paul.Sedrak@bayside.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": " Mosman Municipal Council",
        "Ward name": "",
        "Councillor Name": "Councillor Carolyn Corrigan - Lord Mayor",
        "New or existing counciller": "",
        "Email": "mayor@mosman.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": " Mosman Municipal Council",
        "Ward name": "",
        "Councillor Name": "Councillor Pip Friedrich - Deputy Lord Mayor",
        "New or existing counciller": "",
        "Email": "p.friedrich@mosman.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": " Mosman Municipal Council",
        "Ward name": "",
        "Councillor Name": "Councillor Roy Bendall",
        "New or existing counciller": "",
        "Email": "r.bendall@mosman.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": " Mosman Municipal Council",
        "Ward name": "",
        "Councillor Name": "Councillor Sarah Hughes",
        "New or existing counciller": "",
        "Email": "s.hughes@mosman.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": " Mosman Municipal Council",
        "Ward name": "",
        "Councillor Name": "Councillor Simon Menzies",
        "New or existing counciller": "",
        "Email": "s.menzies@mosman.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": " Mosman Municipal Council",
        "Ward name": "",
        "Councillor Name": "Councillor Libby Moline",
        "New or existing counciller": "",
        "Email": "l.moline@mosman.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": " Mosman Municipal Council",
        "Ward name": "",
        "Councillor Name": "Councillor Michael Randall",
        "New or existing counciller": "",
        "Email": "m.randall@mosman.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Canterbury-Bankstown Council",
        "Ward name": "",
        "Councillor Name": "Councillor Bilal El-Hayek - Lord Mayor",
        "New or existing counciller": "",
        "Email": "Mayor@cbcity.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Canterbury-Bankstown Council",
        "Ward name": "",
        "Councillor Name": "Councillor Rachelle Harika - Deputy Lord Mayor",
        "New or existing counciller": "",
        "Email": "Rachelle.Harika@cbcity.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Canterbury-Bankstown Council",
        "Ward name": "Darani Ward - Bankstown",
        "Councillor Name": "Councillor Khal Asfour",
        "New or existing counciller": "",
        "Email": "Khal.Asfour@cbcity.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Canterbury-Bankstown Council",
        "Ward name": "Darani Ward - Bankstown",
        "Councillor Name": "Councillor George Zakhia",
        "New or existing counciller": "",
        "Email": "George.Zakhia@cbcity.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Canterbury-Bankstown Council",
        "Ward name": "Bura Ward - Bass Hill",
        "Councillor Name": "Councillor Chris Cahill",
        "New or existing counciller": "",
        "Email": "Christopher.Cahill@cbcity.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Canterbury-Bankstown Council",
        "Ward name": "Bura Ward - Bass Hill",
        "Councillor Name": "Councillor Charlie Ishac",
        "New or existing counciller": "",
        "Email": "Charlie.Ishac@cbcity.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Canterbury-Bankstown Council",
        "Ward name": "Budjar Ward - Canterbury",
        "Councillor Name": "Councillor Barbara Coorey",
        "New or existing counciller": "",
        "Email": "Barbara.Coorey@cbcity.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Canterbury-Bankstown Council",
        "Ward name": "Budjar Ward - Canterbury",
        "Councillor Name": "Councillor Jessie Nguyen",
        "New or existing counciller": "",
        "Email": "Jessie.Nguyen@cbcity.nsw.gov.au",
        "Party Affiliation": "Unaligned"
    },
    {
        "Council name": "Canterbury-Bankstown Council",
        "Ward name": "Budjar Ward - Canterbury",
        "Councillor Name": "Councillor Clare Raffan",
        "New or existing counciller": "",
        "Email": "Clare.Raffan@cbcity.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Canterbury-Bankstown Council",
        "Ward name": "Bunya Ward - Revesby",
        "Councillor Name": "Councillor Charbel Abouraad",
        "New or existing counciller": "",
        "Email": "Charbel.Abouraad@cbcity.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Canterbury-Bankstown Council",
        "Ward name": "Bunya Ward - Revesby",
        "Councillor Name": "Councillor Linda Downey",
        "New or existing counciller": "",
        "Email": "Linda.Downey@cbcity.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Canterbury-Bankstown Council",
        "Ward name": "Bunya Ward - Revesby",
        "Councillor Name": "Councillor David Walsh",
        "New or existing counciller": "",
        "Email": "David.Walsh@cbcity.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Canterbury-Bankstown Council",
        "Ward name": "Bunmarra Ward - Roselands",
        "Councillor Name": "Councillor Sazeda Akter",
        "New or existing counciller": "",
        "Email": "Sazeda.Akter@cbcity.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Canterbury-Bankstown Council",
        "Ward name": "Bunmarra Ward - Roselands",
        "Councillor Name": "Councillor Khodr (Karl) Saleh OAM",
        "New or existing counciller": "",
        "Email": "K.Saleh@cbcity.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Canterbury-Bankstown Council",
        "Ward name": "Bunmarra Ward - Roselands",
        "Councillor Name": "Councillor Bhadra Waiba",
        "New or existing counciller": "",
        "Email": "Bhadra.Waiba@cbcity.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Burwood Council",
        "Ward name": "",
        "Councillor Name": "Councillor John Faker - Lord Mayor",
        "New or existing counciller": "",
        "Email": "mayor@burwood.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Burwood Council",
        "Ward name": "",
        "Councillor Name": "Councillor George Mannah - Deputy Lord Mayor",
        "New or existing counciller": "",
        "Email": "george.mannah@burwood.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Burwood Council",
        "Ward name": "",
        "Councillor Name": "Councillor Heather Crichton",
        "New or existing counciller": "",
        "Email": "heather.crichton@burwood.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Burwood Council",
        "Ward name": "",
        "Councillor Name": "Councillor Pascale Esber",
        "New or existing counciller": "",
        "Email": "Pascale.Esber@burwood.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Burwood Council",
        "Ward name": "",
        "Councillor Name": "Councillor Hugo Robinson",
        "New or existing counciller": "",
        "Email": "Hugo.Robinson@burwood.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Burwood Council",
        "Ward name": "",
        "Councillor Name": "Councillor David Hull",
        "New or existing counciller": "",
        "Email": "David.Hull@burwood.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Burwood Council",
        "Ward name": "",
        "Councillor Name": "Councillor Ned Cutcher",
        "New or existing counciller": "",
        "Email": "Ned.Cutcher@burwood.nsw.gov.au",
        "Party Affiliation": "Greens"
    },
    {
        "Council name": "Strathfield Municipal Council",
        "Ward name": "",
        "Councillor Name": "Councillor  Karen Pensabene - Lord Mayor",
        "New or existing counciller": "",
        "Email": "Councillor kpensabene@strathfield.nsw.gov.au ",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Strathfield Municipal Council",
        "Ward name": "",
        "Councillor Name": "Councillor  Sandy Reddy - Deputy Lord Mayor",
        "New or existing counciller": "",
        "Email": "Councillor sreddy@strathfield.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Strathfield Municipal Council",
        "Ward name": "",
        "Councillor Name": "Councillor  Matthew Blackmore",
        "New or existing counciller": "",
        "Email": "Councillor mblackmore@strathfield.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Strathfield Municipal Council",
        "Ward name": "",
        "Councillor Name": "Councillor  Sharangan Maheswaran",
        "New or existing counciller": "",
        "Email": "Councillor smaheswaran@strathfield.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Strathfield Municipal Council",
        "Ward name": "",
        "Councillor Name": "Councillor  Raj Datta",
        "New or existing counciller": "",
        "Email": "Councillor rdatta@strathfield.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Strathfield Municipal Council",
        "Ward name": "",
        "Councillor Name": "Councillor  Benjamin Cai",
        "New or existing counciller": "",
        "Email": "Councillor bcai@strathfield.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Strathfield Municipal Council",
        "Ward name": "",
        "Councillor Name": "Councillor  Nella Hall",
        "New or existing counciller": "",
        "Email": "Councillor nhall@strathfield.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Ryde City Council",
        "Ward name": "",
        "Councillor Name": "Councillor Daniel Han - Deputy Lord Mayor",
        "New or existing counciller": "",
        "Email": "DanielHan@ryde.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Ryde City Council",
        "Ward name": "",
        "Councillor Name": "Councillor Trenton Brown - Lord Mayor",
        "New or existing counciller": "",
        "Email": "mayor@ryde.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Ryde City Council",
        "Ward name": "Central Ward - Denistone East, Meadowbank, Macquarie Park, North Ryde, Ryde, West Ryde",
        "Councillor Name": "Councillor Bernard Purcell",
        "New or existing counciller": "",
        "Email": "BernardP@ryde.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Ryde City Council",
        "Ward name": "Central Ward - Denistone East, Meadowbank, Macquarie Park, North Ryde, Ryde, West Ryde",
        "Councillor Name": "Councillor Katie O’Reilly",
        "New or existing counciller": "",
        "Email": "KatieOr@ryde.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Ryde City Council",
        "Ward name": "Central Ward - Denistone East, Meadowbank, Macquarie Park, North Ryde, Ryde, West Ryde",
        "Councillor Name": "Councillor Sarkis Yedelian OAM",
        "New or existing counciller": "",
        "Email": "SYedelian@ryde.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Ryde City Council",
        "Ward name": "Central Ward - Denistone East, Meadowbank, Macquarie Park, North Ryde, Ryde, West Ryde",
        "Councillor Name": "Councillor Shweta Deshpande",
        "New or existing counciller": "",
        "Email": "ShwetaDe@ryde.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Ryde City Council",
        "Ward name": "East Ward - Chatswood West, Gladesville, East Ryde, North Ryde, Putney, Ryde, Tennyson Point",
        "Councillor Name": "Councillor Jordan Lane",
        "New or existing counciller": "",
        "Email": "JordanL@ryde.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Ryde City Council",
        "Ward name": "East Ward - Chatswood West, Gladesville, East Ryde, North Ryde, Putney, Ryde, Tennyson Point",
        "Councillor Name": "Councillor Penelope (Penny) Pedersen",
        "New or existing counciller": "",
        "Email": "PenelopeP@ryde.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Ryde City Council",
        "Ward name": "East Ward - Chatswood West, Gladesville, East Ryde, North Ryde, Putney, Ryde, Tennyson Point",
        "Councillor Name": "Councillor Roy Maggio",
        "New or existing counciller": "",
        "Email": "RMaggio@ryde.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Ryde City Council",
        "Ward name": "East Ward - Chatswood West, Gladesville, East Ryde, North Ryde, Putney, Ryde, Tennyson Point",
        "Councillor Name": "Councillor Sophie Lara-Watson",
        "New or existing counciller": "",
        "Email": "SophieLW@ryde.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Ryde City Council",
        "Ward name": "West Ward - Denistone, Denistone East, Denistone West, Eastwood, Macquarie Park, Marsfield, Melrose Park, West Ryde",
        "Councillor Name": "Councillor Charles Song",
        "New or existing counciller": "",
        "Email": "CharlesSong@ryde.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Ryde City Council",
        "Ward name": "West Ward - Denistone, Denistone East, Denistone West, Eastwood, Macquarie Park, Marsfield, Melrose Park, West Ryde",
        "Councillor Name": "Councillor Justin Li",
        "New or existing counciller": "",
        "Email": "JustinLi@ryde.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Northern Beaches Council",
        "Ward name": "",
        "Councillor Name": "Councillor Sue Heins - Lord Mayor",
        "New or existing counciller": "",
        "Email": "Sue.Heins@northernbeaches.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Northern Beaches Council",
        "Ward name": "",
        "Councillor Name": "Councillor Georgia Ryburn - Deputy Lord Mayor",
        "New or existing counciller": "",
        "Email": "Georgia.Ryburn@northernbeaches.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Northern Beaches Council",
        "Ward name": "Manly Ward - Balgowlah, Balgowlah Heights, Clontarf, Fairlight, North Balgolwah and Seaforth, most of Manly and Manly Vale, and a small part of Allambie Heights",
        "Councillor Name": "Councillor Candy Bingham",
        "New or existing counciller": "",
        "Email": "Candy.Bingham@northernbeaches.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Northern Beaches Council",
        "Ward name": "Manly Ward - Balgowlah, Balgowlah Heights, Clontarf, Fairlight, North Balgolwah and Seaforth, most of Manly and Manly Vale, and a small part of Allambie Heights",
        "Councillor Name": "Councillor Sarah Grattan",
        "New or existing counciller": "",
        "Email": "Sarah.Grattan@northernbeaches.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Northern Beaches Council",
        "Ward name": "Narrabeen Ward - Collaroy Plateau, Elanora Heights, Narrabeen, North Narrabeen, Oxford Falls and Wheeler Heights, most of Collaroy, Cromer and Warriewood, parts of Belrose, Ingleside and Terrey Hills, and small parts of Beacon Hill, Dee Why, Frenchs Forest, Mona Vale and Narraweena",
        "Councillor Name": "Councillor Bianca Crvelin",
        "New or existing counciller": "",
        "Email": "Bianca.Crvelin@northernbeaches.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Northern Beaches Council",
        "Ward name": "Narrabeen Ward - Collaroy Plateau, Elanora Heights, Narrabeen, North Narrabeen, Oxford Falls and Wheeler Heights, most of Collaroy, Cromer and Warriewood, parts of Belrose, Ingleside and Terrey Hills, and small parts of Beacon Hill, Dee Why, Frenchs Forest, Mona Vale and Narraweena",
        "Councillor Name": "Councillor Vincent De Luca",
        "New or existing counciller": "",
        "Email": "Vincent.DeLuca@northernbeaches.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Northern Beaches Council",
        "Ward name": "Narrabeen Ward - Collaroy Plateau, Elanora Heights, Narrabeen, North Narrabeen, Oxford Falls and Wheeler Heights, most of Collaroy, Cromer and Warriewood, parts of Belrose, Ingleside and Terrey Hills, and small parts of Beacon Hill, Dee Why, Frenchs Forest, Mona Vale and Narraweena",
        "Councillor Name": "Councillor Ruth Robins",
        "New or existing counciller": "",
        "Email": "Ruth.Robins@northernbeaches.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Northern Beaches Council",
        "Ward name": "Curl Curl Ward - Brookvale, Curl Curl, Freshwater, North Curl Curl, North Manly and Queenscliff, most of Dee Why and Narraweena, the north-eastern part of Beacon Hill, and small parts of Allambie Heights, Collaroy, Cromer, Manly and Manly Vale",
        "Councillor Name": "Councillor Kristyn Glanville",
        "New or existing counciller": "",
        "Email": "Kristyn.Glanville@northernbeaches.nsw.gov.au",
        "Party Affiliation": "Greens"
    },
    {
        "Council name": "Northern Beaches Council",
        "Ward name": "Curl Curl Ward - Brookvale, Curl Curl, Freshwater, North Curl Curl, North Manly and Queenscliff, most of Dee Why and Narraweena, the north-eastern part of Beacon Hill, and small parts of Allambie Heights, Collaroy, Cromer, Manly and Manly Vale",
        "Councillor Name": "Councillor David Walton",
        "New or existing counciller": "",
        "Email": "David.Walton@northernbeaches.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Northern Beaches Council",
        "Ward name": "Pittwater Ward - Avalon Beach, Bayview, Bilgola Beach, Bilgola Plateau, Church Point, Clareville, Coasters Retreat, Cottage Point, Currawong Beach, Duffys Forest, Elvina Bay, Great Mackerel Beach, Ku-ring-gai Chase, Lovett Bay, McCarrs Creek, Morning Bay, Newport, Palm Beach, Pittwater, Scotland Island and Whale Beach, most of Mona Vale, parts of Ingleside and Terrey Hills, and small parts of Belrose and Warriewood",
        "Councillor Name": "Councillor Karina Page",
        "New or existing counciller": "",
        "Email": "Karina.Page@northernbeaches.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Northern Beaches Council",
        "Ward name": "Pittwater Ward - Avalon Beach, Bayview, Bilgola Beach, Bilgola Plateau, Church Point, Clareville, Coasters Retreat, Cottage Point, Currawong Beach, Duffys Forest, Elvina Bay, Great Mackerel Beach, Ku-ring-gai Chase, Lovett Bay, McCarrs Creek, Morning Bay, Newport, Palm Beach, Pittwater, Scotland Island and Whale Beach, most of Mona Vale, parts of Ingleside and Terrey Hills, and small parts of Belrose and Warriewood",
        "Councillor Name": "Councillor Michael Gencher",
        "New or existing counciller": "",
        "Email": "Michael.Gencher@northernbeaches.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Northern Beaches Council",
        "Ward name": "Pittwater Ward - Avalon Beach, Bayview, Bilgola Beach, Bilgola Plateau, Church Point, Clareville, Coasters Retreat, Cottage Point, Currawong Beach, Duffys Forest, Elvina Bay, Great Mackerel Beach, Ku-ring-gai Chase, Lovett Bay, McCarrs Creek, Morning Bay, Newport, Palm Beach, Pittwater, Scotland Island and Whale Beach, most of Mona Vale, parts of Ingleside and Terrey Hills, and small parts of Belrose and Warriewood",
        "Councillor Name": "Councillor Miranda Korzy",
        "New or existing counciller": "",
        "Email": "Miranda.Korzy@northernbeaches.nsw.gov.au",
        "Party Affiliation": "Greens"
    },
    {
        "Council name": "Northern Beaches Council",
        "Ward name": "Frenchs Forest Ward - Davidson, Forestville and Killarney Heights, most of Allambie Heights, Beacon Hill, Belrose and Frenchs Forest, and a small part of Manly Vale",
        "Councillor Name": "Councillor Michael Regan",
        "New or existing counciller": "",
        "Email": "Michael.Regan@northernbeaches.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Northern Beaches Council",
        "Ward name": "Frenchs Forest Ward - Davidson, Forestville and Killarney Heights, most of Allambie Heights, Beacon Hill, Belrose and Frenchs Forest, and a small part of Manly Vale",
        "Councillor Name": "Councillor Jose Menano-Pires",
        "New or existing counciller": "",
        "Email": "Jose.Menano-Pires@northernbeaches.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Northern Beaches Council",
        "Ward name": "Frenchs Forest Ward - Davidson, Forestville and Killarney Heights, most of Allambie Heights, Beacon Hill, Belrose and Frenchs Forest, and a small part of Manly Vale",
        "Councillor Name": "Councillor Stuart Sprott",
        "New or existing counciller": "",
        "Email": "Stuart.Sprott@northernbeaches.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "City of Parramatta Council",
        "Ward name": "",
        "Councillor Name": "Councillor Pierre Esber - Lord Mayor",
        "New or existing counciller": "",
        "Email": "lordmayor@cityofparramatta.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "City of Parramatta Council",
        "Ward name": "",
        "Councillor Name": "Councillor Patricia Prociv - Deputy Lord Mayor",
        "New or existing counciller": "",
        "Email": "pprociv@cityofparramatta.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "City of Parramatta Council",
        "Ward name": "Dundas Ward - Dundas, Telopea,  Oatlands, Ermington",
        "Councillor Name": "Councillor Michelle Garrard",
        "New or existing counciller": "",
        "Email": "mgarrard@cityofparramatta.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "City of Parramatta Council",
        "Ward name": "Dundas Ward - Dundas, Telopea,  Oatlands, Ermington",
        "Councillor Name": "Councillor Kellie Darley",
        "New or existing counciller": "",
        "Email": "kdarley@cityofparramatta.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "City of Parramatta Council",
        "Ward name": "Epping Ward - Epping, Dundas Valley, Beecroft (shared), Cheltham (shared), Eastwood (shared)",
        "Councillor Name": "Councillor Lorraine Wearne",
        "New or existing counciller": "",
        "Email": "lwearne@cityofparramatta.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "City of Parramatta Council",
        "Ward name": "Epping Ward - Epping, Dundas Valley, Beecroft (shared), Cheltham (shared), Eastwood (shared)",
        "Councillor Name": "Councillor Cameron MacLean",
        "New or existing counciller": "",
        "Email": "cmaclean@cityofparramatta.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "City of Parramatta Council",
        "Ward name": "North Rocks Ward - North Parramatta, Carlingford, Winston Hills, North Rocks (shared), Baulkham Hills (shared)",
        "Councillor Name": "Councillor Ange Humphries",
        "New or existing counciller": "",
        "Email": "ahumphries@cityofparramatta.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "City of Parramatta Council",
        "Ward name": "North Rocks Ward - North Parramatta, Carlingford, Winston Hills, North Rocks (shared), Baulkham Hills (shared)",
        "Councillor Name": "Councillor Georgina Valjak",
        "New or existing counciller": "",
        "Email": "gvaljak@cityofparramatta.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "City of Parramatta Council",
        "Ward name": "North Rocks Ward - North Parramatta, Carlingford, Winston Hills, North Rocks (shared), Baulkham Hills (shared)",
        "Councillor Name": "Councillor Donna Wang",
        "New or existing counciller": "",
        "Email": "dwang@cityofparramatta.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "City of Parramatta Council",
        "Ward name": "Parramatta Ward - Parramatta, Westmead (shared) , Northmead, Constitution Hill, Wentworthville (shared), Old Toongabbie, Toongabbie (shared), Pendle Hill (shared), Seven Hills (shared)",
        "Councillor Name": "Councillor Henry Green",
        "New or existing counciller": "",
        "Email": "hgreen@cityofparramatta.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "City of Parramatta Council",
        "Ward name": "Parramatta Ward - Parramatta, Westmead (shared) , Northmead, Constitution Hill, Wentworthville (shared), Old Toongabbie, Toongabbie (shared), Pendle Hill (shared), Seven Hills (shared)",
        "Councillor Name": "Councillor Sameer Pandey",
        "New or existing counciller": "",
        "Email": "spandey@cityofparramatta.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "City of Parramatta Council",
        "Ward name": "Parramatta Ward - Parramatta, Westmead (shared) , Northmead, Constitution Hill, Wentworthville (shared), Old Toongabbie, Toongabbie (shared), Pendle Hill (shared), Seven Hills (shared)",
        "Councillor Name": "Councillor Phil Bradley",
        "New or existing counciller": "",
        "Email": "pbradley@cityofparramatta.nsw.gov.au",
        "Party Affiliation": "Greens"
    },
    {
        "Council name": "City of Parramatta Council",
        "Ward name": "Rosehill Ward - Mays Hill (shared), Merrylands (shared), Holroyd (shared), Harris Park, Granville (shared),  Clyde, Rosehill, Camellia, Rydalmere, Auburn (shared), Melrose Park, Wentworth Point, Sydney Olympic Park, Lidcombe (shared), Newington, Silverwater",
        "Councillor Name": "Councillor Paul Noack",
        "New or existing counciller": "",
        "Email": "pnoack@cityofparramatta.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "City of Canada Bay Council",
        "Ward name": "",
        "Councillor Name": "Councillor Michael Megna - Lord Mayor",
        "New or existing counciller": "",
        "Email": "michael.megna@canadabay.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "City of Canada Bay Council",
        "Ward name": "",
        "Councillor Name": "Councillor Joseph Cordaro - Deputy Lord Mayor",
        "New or existing counciller": "",
        "Email": "joseph.cordaro@canadabay.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "City of Canada Bay Council",
        "Ward name": "",
        "Councillor Name": "Councillor Julia Little",
        "New or existing counciller": "",
        "Email": "julia.little@canadabay.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "City of Canada Bay Council",
        "Ward name": "",
        "Councillor Name": "Councillor Charles Jago",
        "New or existing counciller": "",
        "Email": "charles.jago@canadabay.nsw.gov.au",
        "Party Affiliation": "Greens"
    },
    {
        "Council name": "City of Canada Bay Council",
        "Ward name": "",
        "Councillor Name": "Councillor Stephanie Di Pasqua",
        "New or existing counciller": "",
        "Email": "stephanie.dipasqua@canadabay.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "City of Canada Bay Council",
        "Ward name": "",
        "Councillor Name": "Councillor Andrew Ferguson",
        "New or existing counciller": "",
        "Email": "andrew.ferguson@canadabay.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "City of Canada Bay Council",
        "Ward name": "",
        "Councillor Name": "Councillor Anthony Bazouni",
        "New or existing counciller": "",
        "Email": "anthony.bazouni@canadabay.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "City of Canada Bay Council",
        "Ward name": "",
        "Councillor Name": "Councillor Carmela Ruggeri",
        "New or existing counciller": "",
        "Email": "carmela.ruggeri@canadabay.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Ku-Ring-Gai Council",
        "Ward name": "",
        "Councillor Name": "Councillor Sam Ngai - Lord Mayor",
        "New or existing counciller": "",
        "Email": "sngai@krg.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Ku-Ring-Gai Council",
        "Ward name": "",
        "Councillor Name": "Councillor Christine Kay - Deputy Lord Mayor",
        "New or existing counciller": "",
        "Email": "ckay@krg.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Ku-Ring-Gai Council",
        "Ward name": "Comenarra Ward - South Turramurra, West Pymble, parts of Warrawee, Turramurra and Pymble",
        "Councillor Name": "Councillor Jeff Pettett",
        "New or existing counciller": "",
        "Email": "jpettett@krg.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Ku-Ring-Gai Council",
        "Ward name": "Comenarra Ward - South Turramurra, West Pymble, parts of Warrawee, Turramurra and Pymble",
        "Councillor Name": "Councillor Greg Taylor",
        "New or existing counciller": "",
        "Email": "gtaylor@krg.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Ku-Ring-Gai Council",
        "Ward name": "Gordon Ward - Gordon, Killara, East Killara",
        "Councillor Name": "Councillor Barbara Ward",
        "New or existing counciller": "",
        "Email": "bward@krg.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Ku-Ring-Gai Council",
        "Ward name": "Gordon Ward - Gordon, Killara, East Killara",
        "Councillor Name": "Councillor Simon Lennon",
        "New or existing counciller": "",
        "Email": "slennon@krg.nsw.gov.au",
        "Party Affiliation": "Independant Liberal"
    },
    {
        "Council name": "Ku-Ring-Gai Council",
        "Ward name": "Roseville Ward - Roseville, Roseville Chase, Lindfield, East Lindfield",
        "Councillor Name": "Councillor Alec Taylor",
        "New or existing counciller": "",
        "Email": "ataylor@krg.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Ku-Ring-Gai Council",
        "Ward name": "St Ives Ward - St Ives, St Ives Chase, parts of Pymble",
        "Councillor Name": "Councillor Martin Smith",
        "New or existing counciller": "",
        "Email": "martinsmith@krg.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Ku-Ring-Gai Council",
        "Ward name": "Wahroonga Ward - Wahroonga, North Wahroonga, North Turramurra, parts of Warrawee and Turramurra",
        "Councillor Name": "Councillor Cedric Spencer",
        "New or existing counciller": "",
        "Email": "cspencer@krg.nsw.gov.au",
        "Party Affiliation": "Unaligned"
    },
    {
        "Council name": "Ku-Ring-Gai Council",
        "Ward name": "Wahroonga Ward - Wahroonga, North Wahroonga, North Turramurra, parts of Warrawee and Turramurra",
        "Councillor Name": "Councillor Kim Wheatley",
        "New or existing counciller": "",
        "Email": "kwheatley@krg.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Hornsby Shire Council",
        "Ward name": "",
        "Councillor Name": "Councillor Philip Ruddock - Lord Mayor",
        "New or existing counciller": "",
        "Email": "pruddock@hornsby.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Hornsby Shire Council",
        "Ward name": "",
        "Councillor Name": "Councillor Warren Waddell - Deputy Lord Mayor",
        "New or existing counciller": "",
        "Email": "wwaddell@hornsby.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Hornsby Shire Council",
        "Ward name": "A Ward - Asquith, Arcadia, Berowra, Berowra Heights, Brooklyn, Berrilee, Calabash, Cowan, Canoelands, Dangar Island, Dural, Fiddletown, Forest Glen, Glenorie, Galston, Glenhaven, Hornsby Heights, Laughtondale, Mt Colah, Mt Kuring-gai, Maroota, Middle Dural and Singletons Mill",
        "Councillor Name": "Councillor Nathan Tilbury",
        "New or existing counciller": "",
        "Email": "ntilbury@hornsby.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Hornsby Shire Council",
        "Ward name": "A Ward - Asquith, Arcadia, Berowra, Berowra Heights, Brooklyn, Berrilee, Calabash, Cowan, Canoelands, Dangar Island, Dural, Fiddletown, Forest Glen, Glenorie, Galston, Glenhaven, Hornsby Heights, Laughtondale, Mt Colah, Mt Kuring-gai, Maroota, Middle Dural and Singletons Mill",
        "Councillor Name": "Councillor Tania Salitra",
        "New or existing counciller": "",
        "Email": "tsalitra@hornsby.nsw.gov.au",
        "Party Affiliation": "Greens"
    },
    {
        "Council name": "Hornsby Shire Council",
        "Ward name": "B Ward - Hornsby, Normanhurst, Pennant Hills, Thornleigh, Wahroonga, Waitara, Westleigh. Ward C suburbs - Beecroft, Castle Hill, Cheltenham, Cherrybrook, North Epping, West Pennant Hills",
        "Councillor Name": "Councillor Monika Ball",
        "New or existing counciller": "",
        "Email": "mball@hornsby.nsw.gov.au",
        "Party Affiliation": "Greens"
    },
    {
        "Council name": "Hornsby Shire Council",
        "Ward name": "B Ward - Hornsby, Normanhurst, Pennant Hills, Thornleigh, Wahroonga, Waitara, Westleigh. Ward C suburbs - Beecroft, Castle Hill, Cheltenham, Cherrybrook, North Epping, West Pennant Hills",
        "Councillor Name": "Councillor Sallianne McClelland",
        "New or existing counciller": "",
        "Email": "smcclelland@hornsby.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Hornsby Shire Council",
        "Ward name": "B Ward - Hornsby, Normanhurst, Pennant Hills, Thornleigh, Wahroonga, Waitara, Westleigh. Ward C suburbs - Beecroft, Castle Hill, Cheltenham, Cherrybrook, North Epping, West Pennant Hills",
        "Councillor Name": "Councillor Janelle McIntosh",
        "New or existing counciller": "",
        "Email": "jmcintosh@hornsby.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Hornsby Shire Council",
        "Ward name": "C Ward - Beecroft, Castle Hill, Cheltenham, Cherrybrook, North Epping, West Pennant Hills",
        "Councillor Name": "Councillor Emma Heyde",
        "New or existing counciller": "",
        "Email": "eheyde@hornsby.nsw.gov.au",
        "Party Affiliation": "Greens"
    },
    {
        "Council name": "Hornsby Shire Council",
        "Ward name": "C Ward - Beecroft, Castle Hill, Cheltenham, Cherrybrook, North Epping, West Pennant Hills",
        "Councillor Name": "Councillor Verity Greenwood",
        "New or existing counciller": "",
        "Email": "vgreenwood@hornsby.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Hornsby Shire Council",
        "Ward name": "C Ward - Beecroft, Castle Hill, Cheltenham, Cherrybrook, North Epping, West Pennant Hills",
        "Councillor Name": "Councillor Sreeni Pillamarri",
        "New or existing counciller": "",
        "Email": "spillamarri@hornsby.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Wollongong City Council",
        "Ward name": "",
        "Councillor Name": "Councillor Gordon Bradbery - Lord Mayor",
        "New or existing counciller": "",
        "Email": "records@wollongong.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Wollongong City Council",
        "Ward name": "",
        "Councillor Name": "Councillor Tania Brown - Deputy Lord Mayor",
        "New or existing counciller": "",
        "Email": "cr.TBrown@wollongong.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Wollongong City Council",
        "Ward name": "Ward One - Austinmer, Bellambi, Bulli, Clifton, Coalcliff, Coledale, Corrimal, East Corrimal, Fernhill, Helensburgh, Lilyvale, Maddens Plains, Otford, Russell Vale, Scarborough, Stanwell Park, Stanwell Tops, Tarrawanna, Thirroul, Towradgi, Wombarra and Woonona, the Wollongong City parts of Cataract, Darkes Forest and Woronora Dam, and the northern parts of Balgownie, Cordeaux, Fairy Meadow and North Wollongong",
        "Councillor Name": "Councillor Janice Kershaw",
        "New or existing counciller": "",
        "Email": "cr.JKershaw@wollongong.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Wollongong City Council",
        "Ward name": "Ward One - Austinmer, Bellambi, Bulli, Clifton, Coalcliff, Coledale, Corrimal, East Corrimal, Fernhill, Helensburgh, Lilyvale, Maddens Plains, Otford, Russell Vale, Scarborough, Stanwell Park, Stanwell Tops, Tarrawanna, Thirroul, Towradgi, Wombarra and Woonona, the Wollongong City parts of Cataract, Darkes Forest and Woronora Dam, and the northern parts of Balgownie, Cordeaux, Fairy Meadow and North Wollongong",
        "Councillor Name": "Councillor Cameron Walters",
        "New or existing counciller": "",
        "Email": "cr.CWalters@wollongong.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Wollongong City Council",
        "Ward name": "Ward One - Austinmer, Bellambi, Bulli, Clifton, Coalcliff, Coledale, Corrimal, East Corrimal, Fernhill, Helensburgh, Lilyvale, Maddens Plains, Otford, Russell Vale, Scarborough, Stanwell Park, Stanwell Tops, Tarrawanna, Thirroul, Towradgi, Wombarra and Woonona, the Wollongong City parts of Cataract, Darkes Forest and Woronora Dam, and the northern parts of Balgownie, Cordeaux, Fairy Meadow and North Wollongong",
        "Councillor Name": "Councillor Mithra Cox",
        "New or existing counciller": "",
        "Email": "cr.MCox@wollongong.nsw.gov.au",
        "Party Affiliation": "Greens"
    },
    {
        "Council name": "Wollongong City Council",
        "Ward name": "Ward One - Austinmer, Bellambi, Bulli, Clifton, Coalcliff, Coledale, Corrimal, East Corrimal, Fernhill, Helensburgh, Lilyvale, Maddens Plains, Otford, Russell Vale, Scarborough, Stanwell Park, Stanwell Tops, Tarrawanna, Thirroul, Towradgi, Wombarra and Woonona, the Wollongong City parts of Cataract, Darkes Forest and Woronora Dam, and the northern parts of Balgownie, Cordeaux, Fairy Meadow and North Wollongong",
        "Councillor Name": "Councillor Richard Martin",
        "New or existing counciller": "",
        "Email": "cr.RMartin@wollongong.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Wollongong City Council",
        "Ward name": "Ward Two - Coniston, Cordeaux Heights, Gwynneville, Keiraville, Mangerton, Mount Keira, Mount Kembla, Mount Ousley, Mount Pleasant and Wollongong, most of Farmborough Heights, Figtree, Kembla Heights, Mount Saint Thomas and West Wollongong, the northern part of Port Kembla, the southern parts of Balgownie, Fairy Meadow and North Wollongong, the eastern part of Cordeaux, the western part of Unanderra, and a small part of Spring Hill",
        "Councillor Name": "Councillor David Brown",
        "New or existing counciller": "",
        "Email": "cr.DBrown@wollongong.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Wollongong City Council",
        "Ward name": "Ward Two - Coniston, Cordeaux Heights, Gwynneville, Keiraville, Mangerton, Mount Keira, Mount Kembla, Mount Ousley, Mount Pleasant and Wollongong, most of Farmborough Heights, Figtree, Kembla Heights, Mount Saint Thomas and West Wollongong, the northern part of Port Kembla, the southern parts of Balgownie, Fairy Meadow and North Wollongong, the eastern part of Cordeaux, the western part of Unanderra, and a small part of Spring Hill",
        "Councillor Name": "Councillor John Dorahy",
        "New or existing counciller": "",
        "Email": "cr.JDorahy@wollongong.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Wollongong City Council",
        "Ward name": "Ward Two - Coniston, Cordeaux Heights, Gwynneville, Keiraville, Mangerton, Mount Keira, Mount Kembla, Mount Ousley, Mount Pleasant and Wollongong, most of Farmborough Heights, Figtree, Kembla Heights, Mount Saint Thomas and West Wollongong, the northern part of Port Kembla, the southern parts of Balgownie, Fairy Meadow and North Wollongong, the eastern part of Cordeaux, the western part of Unanderra, and a small part of Spring Hill",
        "Councillor Name": "Councillor Cath Blakey",
        "New or existing counciller": "",
        "Email": "cr.CBlakey@wollongong.nsw.gov.au",
        "Party Affiliation": "Greens"
    },
    {
        "Council name": "Wollongong City Council",
        "Ward name": "Ward Three - Avondale, Berkeley, Brownsville, Cleveland, Cringila, Dapto, Dombarton, Haywards Bay, Horsley, Huntley, Kanahooka, Kembla Grange, Koonawarra, Lake Heights, Marshall Mount, Primbee, Warrawong, Windang, Wongawilli and Yallah, the Wollongong City part of Avon, most of Spring Hill, the southern parts of Cordeaux and Port Kembla, the eastern part of Unanderra, and small parts of Farmborough Heights, Figtree, Kembla Heights, Mount Saint Thomas and West Wollongong",
        "Councillor Name": "Councillor Ann Martin",
        "New or existing counciller": "",
        "Email": "cr.AMartin@wollongong.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Wollongong City Council",
        "Ward name": "Ward Three - Avondale, Berkeley, Brownsville, Cleveland, Cringila, Dapto, Dombarton, Haywards Bay, Horsley, Huntley, Kanahooka, Kembla Grange, Koonawarra, Lake Heights, Marshall Mount, Primbee, Warrawong, Windang, Wongawilli and Yallah, the Wollongong City part of Avon, most of Spring Hill, the southern parts of Cordeaux and Port Kembla, the eastern part of Unanderra, and small parts of Farmborough Heights, Figtree, Kembla Heights, Mount Saint Thomas and West Wollongong",
        "Councillor Name": "Councillor Linda Campbell",
        "New or existing counciller": "",
        "Email": "cr.LCampbell@wollongong.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Wollongong City Council",
        "Ward name": "Ward Three - Avondale, Berkeley, Brownsville, Cleveland, Cringila, Dapto, Dombarton, Haywards Bay, Horsley, Huntley, Kanahooka, Kembla Grange, Koonawarra, Lake Heights, Marshall Mount, Primbee, Warrawong, Windang, Wongawilli and Yallah, the Wollongong City part of Avon, most of Spring Hill, the southern parts of Cordeaux and Port Kembla, the eastern part of Unanderra, and small parts of Farmborough Heights, Figtree, Kembla Heights, Mount Saint Thomas and West Wollongong",
        "Councillor Name": "Councillor Dom Figliomeni",
        "New or existing counciller": "",
        "Email": "cr.DFigliomeni@wollongong.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Wollongong City Council",
        "Ward name": "Ward Three - Avondale, Berkeley, Brownsville, Cleveland, Cringila, Dapto, Dombarton, Haywards Bay, Horsley, Huntley, Kanahooka, Kembla Grange, Koonawarra, Lake Heights, Marshall Mount, Primbee, Warrawong, Windang, Wongawilli and Yallah, the Wollongong City part of Avon, most of Spring Hill, the southern parts of Cordeaux and Port Kembla, the eastern part of Unanderra, and small parts of Farmborough Heights, Figtree, Kembla Heights, Mount Saint Thomas and West Wollongong",
        "Councillor Name": "Councillor Elisha Aitken",
        "New or existing counciller": "",
        "Email": "cr.EAitken@wollongong.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Newcastle City Council",
        "Ward name": "",
        "Councillor Name": "Councillor Nuatali Nelmes - Lord Mayor",
        "New or existing counciller": "",
        "Email": "nnelmes@ncc.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Newcastle City Council",
        "Ward name": "",
        "Councillor Name": "Councillor Declan Clausen - Deputy Lord Mayor",
        "New or existing counciller": "",
        "Email": "dclausen@ncc.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Newcastle City Council",
        "Ward name": "Ward One - Carrington, Cooks Hill, Islington, Maryville, Mayfield, Mayfield East, Mayfield North, Mayfield West, Newcastle, Newcastle East, Newcastle West, Stockton, The Hill, Tighes Hill, Warabrook, Wickham",
        "Councillor Name": "Councillor Emma White",
        "New or existing counciller": "",
        "Email": "ewhite@ncc.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Newcastle City Council",
        "Ward name": "Ward One - Carrington, Cooks Hill, Islington, Maryville, Mayfield, Mayfield East, Mayfield North, Mayfield West, Newcastle, Newcastle East, Newcastle West, Stockton, The Hill, Tighes Hill, Warabrook, Wickham",
        "Councillor Name": "Councillor John MacKenzie",
        "New or existing counciller": "",
        "Email": "jmackenzie@ncc.nsw.gov.au",
        "Party Affiliation": "Greens"
    },
    {
        "Council name": "Newcastle City Council",
        "Ward name": "Ward One - Carrington, Cooks Hill, Islington, Maryville, Mayfield, Mayfield East, Mayfield North, Mayfield West, Newcastle, Newcastle East, Newcastle West, Stockton, The Hill, Tighes Hill, Warabrook, Wickham",
        "Councillor Name": "Councillor John Church",
        "New or existing counciller": "",
        "Email": "jchurch@ncc.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Newcastle City Council",
        "Ward name": "Ward Two - Bar Beach, Adamstown, Adamstown Heights, Broadmeadow, Cooks Hill, Hamilton, Hamilton East, Hamilton South, Kotara, Kotara South, Merewether, Merewether Heights, Newcastle West, The Junction",
        "Councillor Name": "Councillor Jenny Barrie",
        "New or existing counciller": "",
        "Email": "jebarrie@ncc.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Newcastle City Council",
        "Ward name": "Ward Two - Bar Beach, Adamstown, Adamstown Heights, Broadmeadow, Cooks Hill, Hamilton, Hamilton East, Hamilton South, Kotara, Kotara South, Merewether, Merewether Heights, Newcastle West, The Junction",
        "Councillor Name": "Councillor Carol Duncan",
        "New or existing counciller": "",
        "Email": "cduncan@ncc.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Newcastle City Council",
        "Ward name": "Ward Two - Bar Beach, Adamstown, Adamstown Heights, Broadmeadow, Cooks Hill, Hamilton, Hamilton East, Hamilton South, Kotara, Kotara South, Merewether, Merewether Heights, Newcastle West, The Junction",
        "Councillor Name": "Councillor Charlotte McCabe",
        "New or existing counciller": "",
        "Email": "cmccabe@ncc.nsw.gov.au",
        "Party Affiliation": "Greens"
    },
    {
        "Council name": "Newcastle City Council",
        "Ward name": "Ward Three - Birmingham Gardens, Callaghan, Georgetown, Jesmond, Hamilton North, Kotara, Lambton, New Lambton, New Lambton Heights, North Lambton, Rankin Park, Wallsend, Waratah, Waratah West",
        "Councillor Name": "Councillor Peta Winney-Baartz",
        "New or existing counciller": "",
        "Email": "pwinneybaartz@ncc.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Newcastle City Council",
        "Ward name": "Ward Three - Birmingham Gardens, Callaghan, Georgetown, Jesmond, Hamilton North, Kotara, Lambton, New Lambton, New Lambton Heights, North Lambton, Rankin Park, Wallsend, Waratah, Waratah West",
        "Councillor Name": "Councillor Katrina Wark",
        "New or existing counciller": "",
        "Email": "kwark@ncc.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Newcastle City Council",
        "Ward name": "Ward Three - Birmingham Gardens, Callaghan, Georgetown, Jesmond, Hamilton North, Kotara, Lambton, New Lambton, New Lambton Heights, North Lambton, Rankin Park, Wallsend, Waratah, Waratah West",
        "Councillor Name": "Councillor Margaret Wood",
        "New or existing counciller": "",
        "Email": "mwood@ncc.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Newcastle City Council",
        "Ward name": "Ward Four - Beresfield, Black Hill, Elermore Vale, Fletcher, Hexham, Lenaghan, Maryland, Minmi, Sandgate, Shortland, Tarro, Wallsend",
        "Councillor Name": "Councillor Deahnna Richardson",
        "New or existing counciller": "",
        "Email": "drichardson@ncc.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Newcastle City Council",
        "Ward name": "Ward Four - Beresfield, Black Hill, Elermore Vale, Fletcher, Hexham, Lenaghan, Maryland, Minmi, Sandgate, Shortland, Tarro, Wallsend",
        "Councillor Name": "Councillor Elizabeth Adamczyk",
        "New or existing counciller": "",
        "Email": "eadamczyk@ncc.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Newcastle City Council",
        "Ward name": "Ward Four - Beresfield, Black Hill, Elermore Vale, Fletcher, Hexham, Lenaghan, Maryland, Minmi, Sandgate, Shortland, Tarro, Wallsend",
        "Councillor Name": "Councillor Callum Pull",
        "New or existing counciller": "",
        "Email": "cpull@ncc.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Lake Macquarie City Council",
        "Ward name": "",
        "Councillor Name": "Councillor Kay Fraser - Lord Mayor",
        "New or existing counciller": "",
        "Email": "kfraser@lakemac.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Lake Macquarie City Council",
        "Ward name": "",
        "Councillor Name": "Councillor Madeline Bishop - Deputy Lord Mayor",
        "New or existing counciller": "",
        "Email": "mbishop@lakemac.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Lake Macquarie City Council",
        "Ward name": "East Ward - Belmont, Belmont North, Belmont South, Bennetts Green, Blacksmiths, Cams Wharf, Catherine Hill Bay, Caves Beach, Charlestown, Crangan Bay, Croudace Bay, Eleebana, Floraville, Gateshead, Jewells, Little Pelican, Marks Point, Moonee, Mount Hutton, Murrays Beach, Nords Wharf, Pelican, Pinny Beach, Redhead, Swansea, Swansea Heads, Tingira Heights, Valentine, Warners Bay, Windale",
        "Councillor Name": "Councillor Adam Shultz",
        "New or existing counciller": "",
        "Email": "ashultz@lakemac.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Lake Macquarie City Council",
        "Ward name": "East Ward - Belmont, Belmont North, Belmont South, Bennetts Green, Blacksmiths, Cams Wharf, Catherine Hill Bay, Caves Beach, Charlestown, Crangan Bay, Croudace Bay, Eleebana, Floraville, Gateshead, Jewells, Little Pelican, Marks Point, Moonee, Mount Hutton, Murrays Beach, Nords Wharf, Pelican, Pinny Beach, Redhead, Swansea, Swansea Heads, Tingira Heights, Valentine, Warners Bay, Windale",
        "Councillor Name": "Councillor Nick Jones",
        "New or existing counciller": "",
        "Email": "njones@lakemac.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Lake Macquarie City Council",
        "Ward name": "East Ward - Belmont, Belmont North, Belmont South, Bennetts Green, Blacksmiths, Cams Wharf, Catherine Hill Bay, Caves Beach, Charlestown, Crangan Bay, Croudace Bay, Eleebana, Floraville, Gateshead, Jewells, Little Pelican, Marks Point, Moonee, Mount Hutton, Murrays Beach, Nords Wharf, Pelican, Pinny Beach, Redhead, Swansea, Swansea Heads, Tingira Heights, Valentine, Warners Bay, Windale",
        "Councillor Name": "Councillor Christine Buckley",
        "New or existing counciller": "",
        "Email": "cbuckley@lakemac.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Lake Macquarie City Council",
        "Ward name": "East Ward - Belmont, Belmont North, Belmont South, Bennetts Green, Blacksmiths, Cams Wharf, Catherine Hill Bay, Caves Beach, Charlestown, Crangan Bay, Croudace Bay, Eleebana, Floraville, Gateshead, Jewells, Little Pelican, Marks Point, Moonee, Mount Hutton, Murrays Beach, Nords Wharf, Pelican, Pinny Beach, Redhead, Swansea, Swansea Heads, Tingira Heights, Valentine, Warners Bay, Windale",
        "Councillor Name": "Councillor Kate Warner",
        "New or existing counciller": "",
        "Email": "kwarner@lakemac.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Lake Macquarie City Council",
        "Ward name": "North Ward - Adamstown Heights, Boolaroo, Cameron Park, Cardiff, Cardiff Heights, Cardiff South, Charlestown, Dudley, Edgeworth, Elermore Vale, Garden Suburb, Glendale,Highfields, Hillsborough, Kahibah, Kotara South, Lakelands, Macquarie Hills, New Lambton Heights, Rankin Park, Seahampton, Speers Point, Warners Bay, West Wallsend, Whitebridge",
        "Councillor Name": "Councillor Brian Adamthwaite",
        "New or existing counciller": "",
        "Email": "badamthwaite@lakemac.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Lake Macquarie City Council",
        "Ward name": "North Ward - Adamstown Heights, Boolaroo, Cameron Park, Cardiff, Cardiff Heights, Cardiff South, Charlestown, Dudley, Edgeworth, Elermore Vale, Garden Suburb, Glendale,Highfields, Hillsborough, Kahibah, Kotara South, Lakelands, Macquarie Hills, New Lambton Heights, Rankin Park, Seahampton, Speers Point, Warners Bay, West Wallsend, Whitebridge",
        "Councillor Name": "Councillor Jack Antcliff",
        "New or existing counciller": "",
        "Email": "jantcliff@lakemac.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Lake Macquarie City Council",
        "Ward name": "North Ward - Adamstown Heights, Boolaroo, Cameron Park, Cardiff, Cardiff Heights, Cardiff South, Charlestown, Dudley, Edgeworth, Elermore Vale, Garden Suburb, Glendale,Highfields, Hillsborough, Kahibah, Kotara South, Lakelands, Macquarie Hills, New Lambton Heights, Rankin Park, Seahampton, Speers Point, Warners Bay, West Wallsend, Whitebridge",
        "Councillor Name": "Councillor Keara Conroy",
        "New or existing counciller": "",
        "Email": "kconroy@lakemac.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Lake Macquarie City Council",
        "Ward name": "North Ward - Adamstown Heights, Boolaroo, Cameron Park, Cardiff, Cardiff Heights, Cardiff South, Charlestown, Dudley, Edgeworth, Elermore Vale, Garden Suburb, Glendale,Highfields, Hillsborough, Kahibah, Kotara South, Lakelands, Macquarie Hills, New Lambton Heights, Rankin Park, Seahampton, Speers Point, Warners Bay, West Wallsend, Whitebridge",
        "Councillor Name": "Councillor Colin Grigg",
        "New or existing counciller": "",
        "Email": "cgrigg@lakemac.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Lake Macquarie City Council",
        "Ward name": "West Ward - Arcadia Vale, Argenton, Awaba, Balcolyn, Balmoral, Barnsley, Blackalls Park, Bolton Point, Bonnells Bay, Booragul, Brightwaters, Buttaba, Carey Bay, Coal Point, Cooranbong, Dora Creek, Eraring, Fassifern, Fennell Bay, Fishing Point, Freemans Waterhole, Holmesville, Kilaben Bay, Killingworth, Mandalong, Marmong Point, Martinsville, Mirrabooka, Morisset, Morisset Park, Myuna Bay, Rathmines, Ryhope, Silverwater, Sunshine, Teralba, Toronto, Wakefield, Wangi Wangi, Windermere Park, Woodrising, Wyee, Wyee Point, Yarrawonga Park",
        "Councillor Name": "Councillor David Belcher",
        "New or existing counciller": "",
        "Email": "dbelcher@lakemac.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Lake Macquarie City Council",
        "Ward name": "West Ward - Arcadia Vale, Argenton, Awaba, Balcolyn, Balmoral, Barnsley, Blackalls Park, Bolton Point, Bonnells Bay, Booragul, Brightwaters, Buttaba, Carey Bay, Coal Point, Cooranbong, Dora Creek, Eraring, Fassifern, Fennell Bay, Fishing Point, Freemans Waterhole, Holmesville, Kilaben Bay, Killingworth, Mandalong, Marmong Point, Martinsville, Mirrabooka, Morisset, Morisset Park, Myuna Bay, Rathmines, Ryhope, Silverwater, Sunshine, Teralba, Toronto, Wakefield, Wangi Wangi, Windermere Park, Woodrising, Wyee, Wyee Point, Yarrawonga Park",
        "Councillor Name": "Councillor Jason Pauling",
        "New or existing counciller": "",
        "Email": "jpauling@lakemac.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Lake Macquarie City Council",
        "Ward name": "West Ward - Arcadia Vale, Argenton, Awaba, Balcolyn, Balmoral, Barnsley, Blackalls Park, Bolton Point, Bonnells Bay, Booragul, Brightwaters, Buttaba, Carey Bay, Coal Point, Cooranbong, Dora Creek, Eraring, Fassifern, Fennell Bay, Fishing Point, Freemans Waterhole, Holmesville, Kilaben Bay, Killingworth, Mandalong, Marmong Point, Martinsville, Mirrabooka, Morisset, Morisset Park, Myuna Bay, Rathmines, Ryhope, Silverwater, Sunshine, Teralba, Toronto, Wakefield, Wangi Wangi, Windermere Park, Woodrising, Wyee, Wyee Point, Yarrawonga Park",
        "Councillor Name": "Councillor Luke Cubis",
        "New or existing counciller": "",
        "Email": "lcubis@lakemac.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Randwick City Council",
        "Ward name": "",
        "Councillor Name": "Councillor Philipa Veitch - Lord Mayor",
        "New or existing counciller": "",
        "Email": "mayor@randwick.nsw.gov.au",
        "Party Affiliation": "Greens",
        "Other notes:": ""
    },
    {
        "Council name": "Randwick City Council",
        "Ward name": "",
        "Councillor Name": "Councillor Alexandra Luxford - Deputy Lord Mayor",
        "New or existing counciller": "",
        "Email": "alexandra.luxford@randwick.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Randwick City Council",
        "Ward name": "Central Ward - Maroubra",
        "Councillor Name": "Councillor Daniel Rosenfeld",
        "New or existing counciller": "",
        "Email": "daniel.rosenfeld@randwick.nsw.gov.au",
        "Party Affiliation": "Liberal",
        "Other notes:": ""
    },
    {
        "Council name": "Randwick City Council",
        "Ward name": "Central Ward - Maroubra",
        "Councillor Name": "Councillor Dylan Parker",
        "New or existing counciller": "",
        "Email": "dylan.parker@randwick.nsw.gov.au",
        "Party Affiliation": "Labor",
        "Other notes:": ""
    },
    {
        "Council name": "Randwick City Council",
        "Ward name": "Central Ward - Maroubra",
        "Councillor Name": "Councillor Kym Chapple",
        "New or existing counciller": "",
        "Email": "kym.chapple@randwick.nsw.gov.au",
        "Party Affiliation": "Greens"
    },
    {
        "Council name": "Randwick City Council",
        "Ward name": "East Ward - Coogee, South Coogee and the remainder of Randwick",
        "Councillor Name": "Councillor Marea Wilson",
        "New or existing counciller": "",
        "Email": "marea.wilson@randwick.nsw.gov.au",
        "Party Affiliation": "Labor",
        "Other notes:": ""
    },
    {
        "Council name": "Randwick City Council",
        "Ward name": "East Ward - Coogee, South Coogee and the remainder of Randwick",
        "Councillor Name": "Councillor Michael Olive",
        "New or existing counciller": "",
        "Email": "michael.olive@randwick.nsw.gov.au",
        "Party Affiliation": "Greens",
        "Other notes:": ""
    },
    {
        "Council name": "Randwick City Council",
        "Ward name": "East Ward - Coogee, South Coogee and the remainder of Randwick",
        "Councillor Name": "Councillor Joanne McCafferty",
        "New or existing counciller": "",
        "Email": "joanne.mccafferty@randwick.nsw.gov.au",
        "Party Affiliation": "Liberal",
        "Other notes:": ""
    },
    {
        "Council name": "Randwick City Council",
        "Ward name": "North Ward - Centennial Park and Clovelly",
        "Councillor Name": "Councillor Rafaela Pandolfini",
        "New or existing counciller": "",
        "Email": "rafaela.pandolfini@randwick.nsw.gov.au",
        "Party Affiliation": "Greens"
    },
    {
        "Council name": "Randwick City Council",
        "Ward name": "North Ward - Centennial Park and Clovelly",
        "Councillor Name": "Councillor Christie Hamilton",
        "New or existing counciller": "",
        "Email": "christie.hamilton@randwick.nsw.gov.au",
        "Party Affiliation": "Liberal",
        "Other notes:": ""
    },
    {
        "Council name": "Randwick City Council",
        "Ward name": "North Ward - Centennial Park and Clovelly",
        "Councillor Name": "Councillor Kathy Neilson",
        "New or existing counciller": "",
        "Email": "kathy.neilson@randwick.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": "Randwick City Council",
        "Ward name": "South Ward - Malabar, Matraville, Chifley, Little Bay, Phillip Bay and La Perouse",
        "Councillor Name": "Councillor Noel D'Souza",
        "New or existing counciller": "",
        "Email": "noel.dsouza@randwick.nsw.gov.au",
        "Party Affiliation": "Independent",
        "Other notes:": ""
    },
    {
        "Council name": "Randwick City Council",
        "Ward name": "South Ward - Malabar, Matraville, Chifley, Little Bay, Phillip Bay and La Perouse",
        "Councillor Name": "Councillor Danny Said",
        "New or existing counciller": "",
        "Email": "danny.said@randwick.nsw.gov.au",
        "Party Affiliation": "Labor",
        "Other notes:": ""
    },
    {
        "Council name": "Randwick City Council",
        "Ward name": "South Ward - Malabar, Matraville, Chifley, Little Bay, Phillip Bay and La Perouse",
        "Councillor Name": "Councillor Bill Burst",
        "New or existing counciller": "",
        "Email": "bill.burst@randwick.nsw.gov.au",
        "Party Affiliation": "Liberal",
        "Other notes:": ""
    },
    {
        "Council name": "Randwick City Council",
        "Ward name": "West Ward - Kingsford, Kensington and part of Randwick",
        "Councillor Name": "Councillor Andrew Hay",
        "New or existing counciller": "",
        "Email": "andrew.hay@randwick.nsw.gov.au",
        "Party Affiliation": "Liberal",
        "Other notes:": ""
    },
    {
        "Council name": "Willoughby City Council",
        "Ward name": "",
        "Councillor Name": "Councillor Tanya Taylor - Lord Mayor",
        "New or existing counciller": "",
        "Email": "tanya.taylor@willoughby.nsw.gov.au",
        "Party Affiliation": "Independent",
        "Other notes:": ""
    },
    {
        "Council name": "Willoughby City Council",
        "Ward name": "",
        "Councillor Name": "Councillor Jam Xia - Deputy Lord Mayor",
        "New or existing counciller": "",
        "Email": "jam.xia@willoughby.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Willoughby City Council",
        "Ward name": "Middle Harbour Ward - Castle Cove, Middle Cove and Willoughby East, and parts of the suburbs of Chatswood, Roseville and North Willoughby",
        "Councillor Name": "Councillor Robert Samuel",
        "New or existing counciller": "",
        "Email": "robert.samuel@willoughby.nsw.gov.au",
        "Party Affiliation": "Independent",
        "Other notes:": ""
    },
    {
        "Council name": "Willoughby City Council",
        "Ward name": "Middle Harbour Ward - Castle Cove, Middle Cove and Willoughby East, and parts of the suburbs of Chatswood, Roseville and North Willoughby",
        "Councillor Name": "Councillor Angelo Rozos",
        "New or existing counciller": "",
        "Email": "angelo.rozos@willoughby.nsw.gov.au",
        "Party Affiliation": "Independent",
        "Other notes:": ""
    },
    {
        "Council name": "Willoughby City Council",
        "Ward name": "Middle Harbour Ward - Castle Cove, Middle Cove and Willoughby East, and parts of the suburbs of Chatswood, Roseville and North Willoughby",
        "Councillor Name": "Councillor Sarkis Mouradian",
        "New or existing counciller": "",
        "Email": "sarkis.mouradian@willoughby.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Willoughby City Council",
        "Ward name": "Naremburn Ward - Artarmon, the Willoughby City part of the suburb of St Leonards, and parts of the suburbs of Naremburn and Willoughby",
        "Councillor Name": "Councillor Georgie Roussac",
        "New or existing counciller": "",
        "Email": "georgie.roussac@willoughby.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Willoughby City Council",
        "Ward name": "Naremburn Ward - Artarmon, the Willoughby City part of the suburb of St Leonards, and parts of the suburbs of Naremburn and Willoughby",
        "Councillor Name": "Councillor Nic Wright",
        "New or existing counciller": "",
        "Email": "nic.wright@willoughby.nsw.gov.au",
        "Party Affiliation": "Independent",
        "Other notes:": ""
    },
    {
        "Council name": "Willoughby City Council",
        "Ward name": "Naremburn Ward - Artarmon, the Willoughby City part of the suburb of St Leonards, and parts of the suburbs of Naremburn and Willoughby",
        "Councillor Name": "Councillor Anna Greco",
        "New or existing counciller": "",
        "Email": "anna.greco@willoughby.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Willoughby City Council",
        "Ward name": "Sailors Bay - Castlecrag and Northbridge, and parts of the suburbs of Chatswood, Naremburn, North Willoughby and Willoughby",
        "Councillor Name": "Councillor Hugh Eriksson",
        "New or existing counciller": "",
        "Email": "hugh.eriksson@willoughby.nsw.gov.au",
        "Party Affiliation": "Independent",
        "Other notes:": ""
    },
    {
        "Council name": "Willoughby City Council",
        "Ward name": "Sailors Bay - Castlecrag and Northbridge, and parts of the suburbs of Chatswood, Naremburn, North Willoughby and Willoughby",
        "Councillor Name": "Councillor John Moratelli",
        "New or existing counciller": "",
        "Email": "john.moratelli@willoughby.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Willoughby City Council",
        "Ward name": "Sailors Bay - Castlecrag and Northbridge, and parts of the suburbs of Chatswood, Naremburn, North Willoughby and Willoughby",
        "Councillor Name": "Councillor Roy McCullagh",
        "New or existing counciller": "",
        "Email": "roy.mccullagh@willoughby.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Willoughby City Council",
        "Ward name": "West - Chatswood West, the Willoughby City part of the suburb of Lane Cove North, and parts of the suburbs of Chatswood and Roseville",
        "Councillor Name": "Councillor Craig Campbell",
        "New or existing counciller": "",
        "Email": "Craig.Campbell@Willoughby.nsw.gov.au",
        "Party Affiliation": "Independent",
        "Other notes:": ""
    },
    {
        "Council name": "Willoughby City Council",
        "Ward name": "West - Chatswood West, the Willoughby City part of the suburb of Lane Cove North, and parts of the suburbs of Chatswood and Roseville",
        "Councillor Name": "Councillor Brendan Zhu",
        "New or existing counciller": "",
        "Email": "Brendon.Zhu@Willoughby.nsw.gov.au",
        "Party Affiliation": "Independent",
        "Other notes:": ""
    },
    {
        "Council name": "Hunter's Hill Council",
        "Ward name": "",
        "Councillor Name": "Councillor Zac Miles - Lord Mayor",
        "New or existing counciller": "",
        "Email": "mayor@huntershill.nsw.gov.au",
        "Party Affiliation": "Liberal",
        "Other notes:": ""
    },
    {
        "Council name": "Hunter's Hill Council",
        "Ward name": "",
        "Councillor Name": "Councillor Elizabeth Krassoi - Deputy Lord Mayor",
        "New or existing counciller": "",
        "Email": "elizabethkrassoi@huntershill.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Hunter's Hill Council",
        "Ward name": "North Ward - Gladesville (part), Hunters Hill (east)",
        "Councillor Name": "Councillor Ross Williams",
        "New or existing counciller": "",
        "Email": "rosswilliams@huntershill.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Hunter's Hill Council",
        "Ward name": "North Ward - Gladesville (part), Hunters Hill (east)",
        "Councillor Name": "Councillor Julia Prieston",
        "New or existing counciller": "",
        "Email": "juliaprieston@huntershill.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Hunter's Hill Council",
        "Ward name": "South Ward - Woolwich, Hunters Hill (west), Huntleys Cove, Huntleys Point, Henley",
        "Councillor Name": "Councillor Tatyana Virgara",
        "New or existing counciller": "",
        "Email": "tatyanavirgara@huntershill.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": "Hunter's Hill Council",
        "Ward name": "South Ward - Woolwich, Hunters Hill (west), Huntleys Cove, Huntleys Point, Henley",
        "Councillor Name": "Councillor Jim Sanderson",
        "New or existing counciller": "",
        "Email": "jimsanderson@huntershill.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": "Hunter's Hill Council",
        "Ward name": "South Ward - Woolwich, Hunters Hill (west), Huntleys Cove, Huntleys Point, Henley",
        "Councillor Name": "Councillor Richard Quinn",
        "New or existing counciller": "",
        "Email": "richardquinn@huntershill.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": " Lane Cove Municipal Council",
        "Ward name": "",
        "Councillor Name": "Councillor Scott Bennison - Lord Mayor",
        "New or existing counciller": "",
        "Email": "dbrookshorn@lanecove.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": " Lane Cove Municipal Council",
        "Ward name": "",
        "Councillor Name": "Councillor David Roenfeldt - Deputy Lord Mayor",
        "New or existing counciller": "",
        "Email": "droenfeldt@lanecove.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": " Lane Cove Municipal Council",
        "Ward name": "Central Ward - Lane Cove (part), Lane Cove West (part), Longueville and Riverview",
        "Councillor Name": "Councillor Katherine Mort",
        "New or existing counciller": "",
        "Email": "kmort@lanecove.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": " Lane Cove Municipal Council",
        "Ward name": "Central Ward - Lane Cove (part), Lane Cove West (part), Longueville and Riverview",
        "Councillor Name": "Councillor Bridget Kennedy",
        "New or existing counciller": "",
        "Email": "bkennedy@lanecove.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": " Lane Cove Municipal Council",
        "Ward name": "Central Ward - Lane Cove (part), Lane Cove West (part), Longueville and Riverview",
        "Councillor Name": "Councillor Kathy Bryla",
        "New or existing counciller": "",
        "Email": "kbryla@lanecove.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": " Lane Cove Municipal Council",
        "Ward name": "East Ward - Northwood, Gore Hill (part), Greenwich, Lane Cove (part)",
        "Councillor Name": "Councillor Merri Southwood",
        "New or existing counciller": "",
        "Email": "msouthwood@lanecove.nsw.gov.au",
        "Party Affiliation": "Independent"
    },
    {
        "Council name": " Lane Cove Municipal Council",
        "Ward name": "East Ward - Northwood, Gore Hill (part), Greenwich, Lane Cove (part)",
        "Councillor Name": "Councillor David Brooks-Horn",
        "New or existing counciller": "",
        "Email": "dbrookshorn@lanecove.nsw.gov.au",
        "Party Affiliation": "Liberal"
    },
    {
        "Council name": " Lane Cove Municipal Council",
        "Ward name": "West Ward - Linley Point, Lane Cove West (part), Lane Cove North (part)",
        "Councillor Name": "Councillor Andrew Zbik",
        "New or existing counciller": "",
        "Email": "azbik@lanecove.nsw.gov.au",
        "Party Affiliation": "Labor"
    },
    {
        "Council name": " Lane Cove Municipal Council",
        "Ward name": "West Ward - Linley Point, Lane Cove West (part), Lane Cove North (part)",
        "Councillor Name": "Councillor Rochelle Flood",
        "New or existing counciller": "",
        "Email": "rflood@lanecove.nsw.gov.au",
        "Party Affiliation": "Greens"
    }
]