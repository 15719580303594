import GSheetReader from "g-sheets-api";
import { GoogleSheetRow, ValidatedGoogleSheetRow } from "../types";
import { cachedSheetsResponse } from "../data/cached-sheets-response";
import { getHeap } from "../utils/utils";

const options = {
  apiKey: "AIzaSyCr3HYpVAJ1iBlb_IjbK_KbltnC0T8C6hY",
  sheetId: "1HoCWmqrQt9dCiW9FxS54iPve_Wz2ef6zM9kzMSZAUvY",
  sheetName: "Sheet1",
  returnAllResults: true,
};


/** Takes in string, and returns a valid party or undefined otherwise */
function validatePartyAffiliation(partyAffiliation: string): 'Independent' | 'Greens' | 'Labor' | undefined {
  if(partyAffiliation === 'Independent' || partyAffiliation === 'Greens' || partyAffiliation === 'Labor') {
    return partyAffiliation;
  }
  return undefined;
}


export async function getDataFromSheet(): Promise<ValidatedGoogleSheetRow[]> {
  const data = await getDataFromSheetWithTimeout(3000, cachedSheetsResponse);
  // Don't filter on ward name not being empty, as some (eg. CoS) don't have wards
  return data
    .filter((row: GoogleSheetRow) => row["Council name"] && row["Email"] && row["Councillor Name"])
    // Check the required fields aren't empty string
    .filter((row: GoogleSheetRow) =>
      row["Council name"] !== ""
      && row["Email"] !== ""
      && row["Councillor Name"] !== "")
    .map((row: GoogleSheetRow) => {
      return {
        ...row,
        "Party Affiliation": validatePartyAffiliation(row["Party Affiliation"]),
      };
    });
}

/**
 * Function that calls async function, and if it doesn't return within `timeout` ms,
 * return the data in arg `fallbackData`
 */
export async function getDataFromSheetWithTimeout(timeout: number, fallbackData: GoogleSheetRow[]): Promise<GoogleSheetRow[]> {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      console.log(`Using fallback data after ${timeout}ms`);
      getHeap().track("delay", { message: `Using fallback data due to timeout after ${timeout}ms` });
      resolve(fallbackData);
    }, timeout);
    getRawDataFromSheet().then((data) => {
      clearTimeout(timeoutId);
      console.log(`Got data from sheet`);
      resolve(data);
    }).catch((error) => {
      getHeap().track("error", { message: error.message, notes: 'code 1: Should still use fallback data' });
      console.error(`Error: ${error.message}. Notes: code 1: Should still use fallback data`);
      // Instant fallback
      resolve(fallbackData);
    });
  });
}


export async function getRawDataFromSheet(): Promise<GoogleSheetRow[]> {
  const rawSheetsData = await (new Promise((resolve, reject) => {
    GSheetReader(
      options,
      (results: any) => {
        resolve(results);
      },
      (error: any) => {
        reject(error);
      }
    );
  }));
  return rawSheetsData as GoogleSheetRow[];
}
