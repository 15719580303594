import { formatWardName } from "./utils"

export function generateEmailBody({ councilName, wardName, myName }: { councilName: string, wardName?: string | undefined, myName: string }) {
  const locationString = wardName === undefined ? councilName : `${formatWardName(wardName)} in ${councilName}`
  return `Dear council representative,

As a resident of ${locationString}, I am writing to express my strong desire for our streets to be made safer and easier for walking and cycling. It is essential that children can walk to school and visit their friends’ houses safely, that we can all walk to the shops to support local businesses, walk our dogs, and easily reach bus stops and train stations.

To achieve this, I urge you to commit to the following if elected:

- Enable quiet local streets throughout our local government area, with a signposted speed of 30 km/h.
- Support local schools to enable all children to walk, cycle, scoot, or catch public transport to school every day.
- Support local businesses by promoting outdoor parklets and implementing slower speeds outside their premises.
- Provide safer street crossings, with the addition of 20 new pedestrian crossings in our local government area each year.
- Provide safe cycling infrastructure to ensure that people can ride bikes safely within our community.

Your commitment to these initiatives will make a tangible difference in the quality of life for all residents. To show your support, I kindly ask that you visit https://www.betterstreets.org.au/2024-nsw-local-candidate-pledge and sign the pledge.

Thank you for considering my concerns. I look forward to seeing your commitment to making our streets safer and more accessible for everyone.

Kind regards,  
${myName}
`
}