import { Fragment } from "react";
import { HeaderAndFooter } from "../components/HeaderAndFooter";
import { ccEmailAddress } from "../config";

export function PrivacyPage() {
  return <HeaderAndFooter clickableTitle={true}>
    <Fragment>
      <h2>Privacy</h2>
      <ul>
        <li>Heap Analytics is used for the purpose
          of measuring the effectiveness of this form
          <ul>
            <li>Your name is <b>not</b> sent in analytics data
              <ul>
                <li>
                  <li>Target text autocapture is also disabled to prevent inadvertent logging</li>
                </li>
              </ul>
            </li>
            <li>The council & ward selection is logged, along with whether an email template is opened</li>
            <li>The call to action <code>mailto:</code> link
              has <code>data-heap-redact-attributes='href'</code>
              to prevent logging of your name via the link content
            </li>
            <li>IP Autocapture is disabled in Heap</li>
          </ul>
        </li>
        <li>Note that <a href={`mailto:${ccEmailAddress}`}> {ccEmailAddress}</a> is
          included in the cc field of pre-populated emails. If you
          would prefer not to share your name & email with Better Streets feel free to remove this.
        </li>
        <li>Councillor data is manually sourced and accurate data is not guaranteed.</li>
      </ul>
      <p>Please contact <a href="mailto:jake@jakecoppinger.com">Jake Coppinger</a> if you have any
        concerns, feedback or spot any errors.</p>
    </Fragment>
  </HeaderAndFooter >

}