import { Link } from "react-router-dom/dist/index";
import { FC, PropsWithChildren } from "react";
import styled from "@emotion/styled";

export const WideWrapper = styled.div`
  max-width:90%;
  margin: 0 auto;
  img {
    max-width:100%;
  }
  p,h1,h2,h3,h4,h5,h6 {
    width: 700px;
    max-width: 90%;
  }
`;


export const HeaderAndFooter = ({ children, clickableTitle = false }: { children: any, clickableTitle?: boolean }) => {
  return (
    <div className={'hundred-vh-height'} style={{
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '90%',
      margin: '0 auto',
      width: '700px',
    }}>
      <div style={{
        flex: '1'
      }}>
        {clickableTitle ? <Link to="/"><h1>Request Better Streets</h1></Link> : <h1>Request Better Streets</h1>}
        {children}
      </div>
      <footer
        style={{
          fontSize: '13px',
          color: 'grey',
        }}
      >
        <hr style={{
          border: 'none',
          borderTop: '1px solid #ccc',
          margin: '1em 0',
        }} />


        <p>This form does not log any personally identifiable information - see <Link to="/privacy">privacy</Link> page for more info.</p>
        <p>Built by <a
          target="_blank" rel="noopener noreferrer"
          href="https://jakecoppinger.com">Jake Coppinger</a> this week for <a
            target="_blank" rel="noopener noreferrer"
            href="https://www.betterstreets.info/">Better Streets</a>.
        </p>
      </footer>
    </div>
  );
};

