import { Heap, ValidatedGoogleSheetRow } from "../types";

export function generateMailtoHref({ toEmails, ccEmails, bccEmails, subject, body, }:
  { toEmails: string[], ccEmails?: string[], bccEmails?: string[], subject: string, body: string }) {
  const to = toEmails.join(",");
  const ccPart = ccEmails ? `&cc=${ccEmails.join(",")}` : '';
  const bccPart = bccEmails ? `&bcc=${bccEmails.join(",")}` : '';

  const mailtoHref = `mailto:${to}?subject=${subject}${ccPart}${bccPart}&body=${encodeURIComponent(body)}`;
  return mailtoHref;
}


/**
 * generates gmail compose email like
 * https://mail.google.com/mail/?view=cm&fs=1&to=someone@example.com&su=SUBJECT&body=BODY&bcc=someone.else@example.com
 */
export function generateGmailWebComposeHref({ toEmails, ccEmails, bccEmails, subject, body, }:
  { toEmails: string[], ccEmails?: string[], bccEmails?: string[], subject: string, body: string }) {
  const to = toEmails.join(",");
  const ccPart = ccEmails ? `&cc=${ccEmails.join(",")}` : '';
  const bccPart = bccEmails ? `&bcc=${bccEmails.join(",")}` : '';
  const mailtoHref = `https://mail.google.com/mail/?view=cm&fs=1&to=${to}&su=${subject}${ccPart}${bccPart}&body=${encodeURIComponent(body)}`;
  return mailtoHref;
}

/**
 * generates gmail mailto compose link like
 * googlegmail://co?subject=Hello&body=Hi
 */
export function generateGmailAppComposeHref({ toEmails, ccEmails, bccEmails, subject, body, }:
  { toEmails: string[], ccEmails?: string[], bccEmails?: string[], subject: string, body: string }) {
  const to = toEmails.join(",");
  const ccPart = ccEmails ? `&cc=${ccEmails.join(",")}` : '';
  const bccPart = bccEmails ? `&bcc=${bccEmails.join(",")}` : '';
  const mailtoHref = `googlegmail://co?to=${to}&subject=${subject}${ccPart}${bccPart}&body=${encodeURIComponent(body)}`;
  return mailtoHref;
}

/**
 * API reference: https://developers.heap.io/reference/client-side-apis-overview
 * @returns API
 */
export function getHeap(): Heap {
  // @ts-ignore
  return window.heap;
}

export function generateCandidatesToEmail({emailList,
  selectedCouncil,
  selectedCouncilWard
}: {
  emailList: ValidatedGoogleSheetRow[],
  selectedCouncil: string
  selectedCouncilWard: string | undefined | null
}): ValidatedGoogleSheetRow[] {
  const relevantRows = emailList.filter(row => row["Council name"] === selectedCouncil);
  if(selectedCouncilWard !== undefined && selectedCouncilWard !== '' && selectedCouncilWard !== null) {
    // Eg. lord mayor of waverley
    const rowsWithoutWard = relevantRows.filter(row => row["Ward name"] === undefined || row["Ward name"] === "");
    const rowsWithWard = relevantRows.filter(row => row["Ward name"] === selectedCouncilWard);
    return [...rowsWithoutWard, ...rowsWithWard]
  }
  return relevantRows
}


/**
 * If ward name has ` - ` in it, it will be split into two parts and the first part will be returned.
 * @param wardName 
 */
export function formatWardName(wardName: string) {
  if(wardName.includes(" - ")) {
    return wardName.split(" - ")[0]
  }
  return wardName;
}

export function doesCouncilHaveWards(candidatesToEmail: ValidatedGoogleSheetRow[]): boolean {
  return candidatesToEmail
    .some(row => row["Ward name"] !== null && row["Ward name"] !== '' && row["Ward name"] !== 'N/A' && row["Ward name"] !== 'NA');
}